import { FormEvent, useState } from 'react';
import { useAuthStore } from '../../model/auth/AuthStore';
import { TouwiInput } from '../common/input/TouwiInput';
import { TouwiButton } from '../common/button/TouwiButton';

export function LoginBlock() {
  const [apiKey, setApiKey] = useState('');
  const [authenticate, isAuthenticating] = useAuthStore((state) => [
    state.setToken,
    state.isAuthenticating,
  ]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!apiKey.trim()) {
      return;
    }

    authenticate(apiKey);
  };

  return (
    <div className="flex w-[95%] flex-col gap-5 rounded-xl border-2 p-4 md:w-[50%]">
      <div className="text-xl">Login to HC API</div>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-5">
          <TouwiInput
            value={apiKey}
            onValue={setApiKey}
            disabled={isAuthenticating}
            inputId="access-token"
            label="Access token"
            type="password"
          />
          <TouwiButton
            disabled={isAuthenticating}
            type="submit"
            text={isAuthenticating ? 'Logging in...' : 'Login'}
            style="normal"
          />
        </div>
      </form>
    </div>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * SA Hundt API service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: e79ba5b2bb8125642aeb209758d25da6fcfbd8a8
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base';

/**
 *
 * @export
 * @interface AccelerometerSampleValueDto
 */
export interface AccelerometerSampleValueDto {
  /**
   *
   * @type {number}
   * @memberof AccelerometerSampleValueDto
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof AccelerometerSampleValueDto
   */
  y: number;
  /**
   *
   * @type {number}
   * @memberof AccelerometerSampleValueDto
   */
  z: number;
}
/**
 *
 * @export
 * @interface ActionResultDto
 */
export interface ActionResultDto {
  /**
   *
   * @type {boolean}
   * @memberof ActionResultDto
   */
  isSuccess: boolean;
}
/**
 *
 * @export
 * @interface ActivateSimInputDto
 */
export interface ActivateSimInputDto {
  /**
   * SIM card ICCID identifier
   * @type {string}
   * @memberof ActivateSimInputDto
   */
  simId: string;
}
/**
 *
 * @export
 * @interface AlflexGatewayDto
 */
export interface AlflexGatewayDto {
  /**
   *
   * @type {string}
   * @memberof AlflexGatewayDto
   */
  gatewayId: string;
  /**
   *
   * @type {string}
   * @memberof AlflexGatewayDto
   */
  door1SensorId?: string;
  /**
   *
   * @type {string}
   * @memberof AlflexGatewayDto
   */
  door2SensorId?: string;
  /**
   *
   * @type {string}
   * @memberof AlflexGatewayDto
   */
  referenceSensorId?: string;
  /**
   *
   * @type {string}
   * @memberof AlflexGatewayDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface AlflexInputVendorDataDto
 */
export interface AlflexInputVendorDataDto {
  /**
   *
   * @type {string}
   * @memberof AlflexInputVendorDataDto
   */
  type: AlflexInputVendorDataDtoTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof AlflexInputVendorDataDto
   */
  fastData?: boolean;
}

export const AlflexInputVendorDataDtoTypeEnum = {
  Alflex: 'ALFLEX',
} as const;

export type AlflexInputVendorDataDtoTypeEnum =
  (typeof AlflexInputVendorDataDtoTypeEnum)[keyof typeof AlflexInputVendorDataDtoTypeEnum];

/**
 *
 * @export
 * @interface AlflexVendorDataDto
 */
export interface AlflexVendorDataDto {
  /**
   *
   * @type {string}
   * @memberof AlflexVendorDataDto
   */
  type: AlflexVendorDataDtoTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof AlflexVendorDataDto
   */
  fastData?: boolean;
}

export const AlflexVendorDataDtoTypeEnum = {
  Alflex: 'ALFLEX',
} as const;

export type AlflexVendorDataDtoTypeEnum =
  (typeof AlflexVendorDataDtoTypeEnum)[keyof typeof AlflexVendorDataDtoTypeEnum];

/**
 *
 * @export
 * @interface ApiTokenCreateInputDto
 */
export interface ApiTokenCreateInputDto {
  /**
   *
   * @type {string}
   * @memberof ApiTokenCreateInputDto
   */
  expiresAt: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenCreateInputDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface ApiTokenDto
 */
export interface ApiTokenDto {
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  expiresAt: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  firstUsageAt?: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ApiTokenDto
   */
  token: string;
}
/**
 *
 * @export
 * @interface CommonCommandResponsePayloadDto
 */
export interface CommonCommandResponsePayloadDto {
  /**
   *
   * @type {number}
   * @memberof CommonCommandResponsePayloadDto
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof CommonCommandResponsePayloadDto
   */
  message?: string;
}
/**
 *
 * @export
 * @interface D2cFwImageDto
 */
export interface D2cFwImageDto {
  /**
   *
   * @type {number}
   * @memberof D2cFwImageDto
   */
  sizeBytes: number;
  /**
   *
   * @type {string}
   * @memberof D2cFwImageDto
   */
  downloadUrl: string;
}
/**
 *
 * @export
 * @interface DateFilterDto
 */
export interface DateFilterDto {
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  gt?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  gte?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  lt?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  lte?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  eq?: string;
  /**
   *
   * @type {string}
   * @memberof DateFilterDto
   */
  ne?: string;
}
/**
 *
 * @export
 * @interface DoorEventDto
 */
export interface DoorEventDto {
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof DoorEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof DoorEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof DoorEventDto
   */
  type: DoorEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  durationMillis: number;
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  accelerationDurationMillis?: number;
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  decelerationDurationMillis?: number;
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  accelerationPeakMPSS?: number;
  /**
   *
   * @type {number}
   * @memberof DoorEventDto
   */
  decelerationPeakMPSS?: number;
  /**
   *
   * @type {string}
   * @memberof DoorEventDto
   */
  sensorId: string;
  /**
   *
   * @type {string}
   * @memberof DoorEventDto
   */
  direction: DoorEventDtoDirectionEnum;
  /**
   *
   * @type {string}
   * @memberof DoorEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof DoorEventDto
   */
  receivedAt: string;
}

export const DoorEventDtoTypeEnum = {
  DoorEvent: 'DOOR_EVENT',
} as const;

export type DoorEventDtoTypeEnum =
  (typeof DoorEventDtoTypeEnum)[keyof typeof DoorEventDtoTypeEnum];
export const DoorEventDtoDirectionEnum = {
  Opening: 'OPENING',
  Closing: 'CLOSING',
} as const;

export type DoorEventDtoDirectionEnum =
  (typeof DoorEventDtoDirectionEnum)[keyof typeof DoorEventDtoDirectionEnum];

/**
 *
 * @export
 * @interface EventsPageDto
 */
export interface EventsPageDto {
  /**
   *
   * @type {Array<EventsPageDtoItemsInner>}
   * @memberof EventsPageDto
   */
  items: Array<EventsPageDtoItemsInner>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof EventsPageDto
   */
  meta: PageMetaDto;
}
/**
 * @type EventsPageDtoItemsInner
 * @export
 */
export type EventsPageDtoItemsInner =
  | DoorEventDto
  | GwPowerLostEventDto
  | RecordingStartedEventDto
  | RecordingStoppedEventDto
  | RefPressureUpdateEventDto
  | RideEventDto
  | SensorBatteryEventDto
  | SensorConnectionLostEventDto
  | SensorConnectionRecoveredEventDto;

/**
 *
 * @export
 * @interface FwImageDto
 */
export interface FwImageDto {
  /**
   *
   * @type {number}
   * @memberof FwImageDto
   */
  sizeBytes: number;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  type: FwImageDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  sha256Digest: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  downloadUrl: string;
  /**
   *
   * @type {string}
   * @memberof FwImageDto
   */
  path: string;
}

export const FwImageDtoTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;

export type FwImageDtoTypeEnum =
  (typeof FwImageDtoTypeEnum)[keyof typeof FwImageDtoTypeEnum];

/**
 *
 * @export
 * @interface FwImageUpdateInputDto
 */
export interface FwImageUpdateInputDto {
  /**
   *
   * @type {string}
   * @memberof FwImageUpdateInputDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface FwImagesPageDto
 */
export interface FwImagesPageDto {
  /**
   *
   * @type {Array<FwImageDto>}
   * @memberof FwImagesPageDto
   */
  items: Array<FwImageDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof FwImagesPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface GatewayAttributeEventsBufferingMaxIntervalSecValueDto
 */
export interface GatewayAttributeEventsBufferingMaxIntervalSecValueDto {
  /**
   *
   * @type {number}
   * @memberof GatewayAttributeEventsBufferingMaxIntervalSecValueDto
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeEventsBufferingMaxIntervalSecValueDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GatewayAttributeIntValueDto
 */
export interface GatewayAttributeIntValueDto {
  /**
   *
   * @type {number}
   * @memberof GatewayAttributeIntValueDto
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeIntValueDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GatewayAttributeModeValueDto
 */
export interface GatewayAttributeModeValueDto {
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeModeValueDto
   */
  value: GatewayAttributeModeValueDtoValueEnum;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeModeValueDto
   */
  updatedAt: string;
}

export const GatewayAttributeModeValueDtoValueEnum = {
  Normal: 'NORMAL',
  Advanced: 'ADVANCED',
  Engineering: 'ENGINEERING',
} as const;

export type GatewayAttributeModeValueDtoValueEnum =
  (typeof GatewayAttributeModeValueDtoValueEnum)[keyof typeof GatewayAttributeModeValueDtoValueEnum];

/**
 *
 * @export
 * @interface GatewayAttributeNetworkModeValueDto
 */
export interface GatewayAttributeNetworkModeValueDto {
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeNetworkModeValueDto
   */
  value: GatewayAttributeNetworkModeValueDtoValueEnum;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeNetworkModeValueDto
   */
  updatedAt: string;
}

export const GatewayAttributeNetworkModeValueDtoValueEnum = {
  Gsm: 'GSM',
  Gprs: 'GPRS',
  Edge: 'EDGE',
  Hsdpa: 'HSDPA',
  Lte: 'LTE',
} as const;

export type GatewayAttributeNetworkModeValueDtoValueEnum =
  (typeof GatewayAttributeNetworkModeValueDtoValueEnum)[keyof typeof GatewayAttributeNetworkModeValueDtoValueEnum];

/**
 *
 * @export
 * @interface GatewayAttributeNetworkSignalStrengthLevelValueDto
 */
export interface GatewayAttributeNetworkSignalStrengthLevelValueDto {
  /**
   *
   * @type {number}
   * @memberof GatewayAttributeNetworkSignalStrengthLevelValueDto
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeNetworkSignalStrengthLevelValueDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GatewayAttributeStringValueDto
 */
export interface GatewayAttributeStringValueDto {
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeStringValueDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof GatewayAttributeStringValueDto
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GatewayDesiredStateAttributesDto
 */
export interface GatewayDesiredStateAttributesDto {
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  sensorsFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  gatewayFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  referenceSensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  door1SensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  door2SensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeModeValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  mode?: GatewayAttributeModeValueDto;
  /**
   *
   * @type {GatewayAttributeEventsBufferingMaxIntervalSecValueDto}
   * @memberof GatewayDesiredStateAttributesDto
   */
  eventsBufferingMaxIntervalSec?: GatewayAttributeEventsBufferingMaxIntervalSecValueDto;
}
/**
 *
 * @export
 * @interface GatewayDesiredStateUpdateInputDto
 */
export interface GatewayDesiredStateUpdateInputDto {
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  gatewayFirmwareVersion?: string | null;
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  sensorsFirmwareVersion?: string | null;
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  referenceSensorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  door1SensorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  door2SensorId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  mode?: GatewayDesiredStateUpdateInputDtoModeEnum | null;
  /**
   *
   * @type {number}
   * @memberof GatewayDesiredStateUpdateInputDto
   */
  eventsBufferingMaxIntervalSec?: number | null;
}

export const GatewayDesiredStateUpdateInputDtoModeEnum = {
  Normal: 'NORMAL',
  Advanced: 'ADVANCED',
  Engineering: 'ENGINEERING',
} as const;

export type GatewayDesiredStateUpdateInputDtoModeEnum =
  (typeof GatewayDesiredStateUpdateInputDtoModeEnum)[keyof typeof GatewayDesiredStateUpdateInputDtoModeEnum];

/**
 *
 * @export
 * @interface GatewayDto
 */
export interface GatewayDto {
  /**
   * Optional free-form setup identifier.<br>When defined, it can link the gateway and related field data to external entities representing physical lift setups.
   * @type {string}
   * @memberof GatewayDto
   */
  setupId?: string | null;
  /**
   * Optional free-form description of the gateway. When defined can be used at the user\'s discretion.
   * @type {string}
   * @memberof GatewayDto
   */
  description?: string | null;
  /**
   * Optional free-form tags of the gateway. These tags can be used for query or batch update operations.
   * @type {Array<string>}
   * @memberof GatewayDto
   */
  tags: Array<string>;
  /**
   *
   * @type {Array<GatewayDtoVendorDataInner>}
   * @memberof GatewayDto
   */
  vendorData?: Array<GatewayDtoVendorDataInner>;
  /**
   *
   * @type {string}
   * @memberof GatewayDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GatewayDto
   */
  type: GatewayDtoTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof GatewayDto
   */
  isOnline: boolean;
}

export const GatewayDtoTypeEnum = {
  Touwi: 'TOUWI',
  Alflex: 'ALFLEX',
} as const;

export type GatewayDtoTypeEnum =
  (typeof GatewayDtoTypeEnum)[keyof typeof GatewayDtoTypeEnum];

/**
 * @type GatewayDtoVendorDataInner
 * @export
 */
export type GatewayDtoVendorDataInner = AlflexVendorDataDto;

/**
 *
 * @export
 * @interface GatewayReportedStateAttributesDto
 */
export interface GatewayReportedStateAttributesDto {
  /**
   *
   * @type {GatewayAttributeIntValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door1SensorBatteryLevel?: GatewayAttributeIntValueDto;
  /**
   *
   * @type {GatewayAttributeIntValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door2SensorBatteryLevel?: GatewayAttributeIntValueDto;
  /**
   *
   * @type {GatewayAttributeIntValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  referenceSensorBatteryLevel?: GatewayAttributeIntValueDto;
  /**
   *
   * @type {GatewayAttributeNetworkModeValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  networkMode?: GatewayAttributeNetworkModeValueDto;
  /**
   *
   * @type {GatewayAttributeNetworkSignalStrengthLevelValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  networkSignalStrength?: GatewayAttributeNetworkSignalStrengthLevelValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door1SensorFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door2SensorFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  referenceSensorFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  lastNetwork?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  gatewayFirmwareVersion?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  referenceSensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door1SensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeStringValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  door2SensorId?: GatewayAttributeStringValueDto;
  /**
   *
   * @type {GatewayAttributeModeValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  mode?: GatewayAttributeModeValueDto;
  /**
   *
   * @type {GatewayAttributeEventsBufferingMaxIntervalSecValueDto}
   * @memberof GatewayReportedStateAttributesDto
   */
  eventsBufferingMaxIntervalSec?: GatewayAttributeEventsBufferingMaxIntervalSecValueDto;
}
/**
 *
 * @export
 * @interface GatewayStateDto
 */
export interface GatewayStateDto {
  /**
   *
   * @type {GatewayDesiredStateAttributesDto}
   * @memberof GatewayStateDto
   */
  desired: GatewayDesiredStateAttributesDto;
  /**
   *
   * @type {GatewayReportedStateAttributesDto}
   * @memberof GatewayStateDto
   */
  reported: GatewayReportedStateAttributesDto;
  /**
   *
   * @type {string}
   * @memberof GatewayStateDto
   */
  gatewayId: string;
}
/**
 *
 * @export
 * @interface GatewayStateResponseDto
 */
export interface GatewayStateResponseDto {
  /**
   *
   * @type {number}
   * @memberof GatewayStateResponseDto
   */
  timeSinceBootMillis: number;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  mode?: GatewayStateResponseDtoModeEnum;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  gatewayTime: string;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  lastDoorEventTime?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  lastRideEventTime?: string;
  /**
   *
   * @type {number}
   * @memberof GatewayStateResponseDto
   */
  nProcessedAccelSamples?: number;
  /**
   *
   * @type {number}
   * @memberof GatewayStateResponseDto
   */
  nProcessedPressureSamples?: number;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  door1SensorLastConnectedAt?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  door2SensorLastConnectedAt?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayStateResponseDto
   */
  refSensorLastConnectedAt?: string;
  /**
   *
   * @type {AccelerometerSampleValueDto}
   * @memberof GatewayStateResponseDto
   */
  lastAccelSampleValue?: AccelerometerSampleValueDto;
  /**
   *
   * @type {PressureSampleValueDto}
   * @memberof GatewayStateResponseDto
   */
  lastPressureSampleValue?: PressureSampleValueDto;
}

export const GatewayStateResponseDtoModeEnum = {
  Normal: 'NORMAL',
  Advanced: 'ADVANCED',
  Engineering: 'ENGINEERING',
} as const;

export type GatewayStateResponseDtoModeEnum =
  (typeof GatewayStateResponseDtoModeEnum)[keyof typeof GatewayStateResponseDtoModeEnum];

/**
 *
 * @export
 * @interface GatewayUpdateInputDto
 */
export interface GatewayUpdateInputDto {
  /**
   * Optional free-form setup identifier.<br>When defined, it can link the gateway and related field data to external entities representing physical lift setups.
   * @type {string}
   * @memberof GatewayUpdateInputDto
   */
  setupId?: string | null;
  /**
   * Optional free-form description of the gateway. When defined can be used at the user\'s discretion.
   * @type {string}
   * @memberof GatewayUpdateInputDto
   */
  description?: string | null;
  /**
   * Optional free-form tags of the gateway. These tags can be used for query or batch update operations.
   * @type {Array<string>}
   * @memberof GatewayUpdateInputDto
   */
  tags?: Array<string>;
  /**
   *
   * @type {Array<GatewayUpdateInputDtoVendorDataInner>}
   * @memberof GatewayUpdateInputDto
   */
  vendorData?: Array<GatewayUpdateInputDtoVendorDataInner>;
}
/**
 * @type GatewayUpdateInputDtoVendorDataInner
 * @export
 */
export type GatewayUpdateInputDtoVendorDataInner = AlflexInputVendorDataDto;

/**
 *
 * @export
 * @interface GatewaysDesiredStateUpdateInputDto
 */
export interface GatewaysDesiredStateUpdateInputDto {
  /**
   *
   * @type {GatewaysDesiredStatesUpdateQueryParamsDto}
   * @memberof GatewaysDesiredStateUpdateInputDto
   */
  query?: GatewaysDesiredStatesUpdateQueryParamsDto;
  /**
   *
   * @type {GatewayDesiredStateUpdateInputDto}
   * @memberof GatewaysDesiredStateUpdateInputDto
   */
  input: GatewayDesiredStateUpdateInputDto;
}
/**
 *
 * @export
 * @interface GatewaysDesiredStatesUpdateQueryParamsDto
 */
export interface GatewaysDesiredStatesUpdateQueryParamsDto {
  /**
   * Mandatory when `gatewayFirmwareVersion` or `sensorsFirmwareVersion` is provided
   * @type {string}
   * @memberof GatewaysDesiredStatesUpdateQueryParamsDto
   */
  gatewayType?: GatewaysDesiredStatesUpdateQueryParamsDtoGatewayTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof GatewaysDesiredStatesUpdateQueryParamsDto
   */
  gatewaysIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GatewaysDesiredStatesUpdateQueryParamsDto
   */
  gatewaysTags?: Array<string>;
}

export const GatewaysDesiredStatesUpdateQueryParamsDtoGatewayTypeEnum = {
  Touwi: 'TOUWI',
  Alflex: 'ALFLEX',
} as const;

export type GatewaysDesiredStatesUpdateQueryParamsDtoGatewayTypeEnum =
  (typeof GatewaysDesiredStatesUpdateQueryParamsDtoGatewayTypeEnum)[keyof typeof GatewaysDesiredStatesUpdateQueryParamsDtoGatewayTypeEnum];

/**
 *
 * @export
 * @interface GatewaysPageDto
 */
export interface GatewaysPageDto {
  /**
   *
   * @type {Array<GatewayDto>}
   * @memberof GatewaysPageDto
   */
  items: Array<GatewayDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof GatewaysPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface GatewaysStateUpdateResultDto
 */
export interface GatewaysStateUpdateResultDto {
  /**
   *
   * @type {number}
   * @memberof GatewaysStateUpdateResultDto
   */
  nUpdated: number;
}
/**
 *
 * @export
 * @interface GetHealth200Response
 */
export interface GetHealth200Response {
  /**
   *
   * @type {string}
   * @memberof GetHealth200Response
   */
  status?: string;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  info?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  error?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth200Response
   */
  details?: { [key: string]: GetHealth200ResponseInfoValue };
}
/**
 *
 * @export
 * @interface GetHealth200ResponseInfoValue
 */
export interface GetHealth200ResponseInfoValue {
  [key: string]: string;

  /**
   *
   * @type {string}
   * @memberof GetHealth200ResponseInfoValue
   */
  status: string;
}
/**
 *
 * @export
 * @interface GetHealth503Response
 */
export interface GetHealth503Response {
  /**
   *
   * @type {string}
   * @memberof GetHealth503Response
   */
  status?: string;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  info?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  error?: { [key: string]: GetHealth200ResponseInfoValue } | null;
  /**
   *
   * @type {{ [key: string]: GetHealth200ResponseInfoValue; }}
   * @memberof GetHealth503Response
   */
  details?: { [key: string]: GetHealth200ResponseInfoValue };
}
/**
 *
 * @export
 * @interface GwPowerLostEventDto
 */
export interface GwPowerLostEventDto {
  /**
   *
   * @type {number}
   * @memberof GwPowerLostEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof GwPowerLostEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof GwPowerLostEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof GwPowerLostEventDto
   */
  type: GwPowerLostEventDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GwPowerLostEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof GwPowerLostEventDto
   */
  receivedAt: string;
}

export const GwPowerLostEventDtoTypeEnum = {
  GwPowerLostEvent: 'GW_POWER_LOST_EVENT',
} as const;

export type GwPowerLostEventDtoTypeEnum =
  (typeof GwPowerLostEventDtoTypeEnum)[keyof typeof GwPowerLostEventDtoTypeEnum];

/**
 *
 * @export
 * @interface ImportResponseDto
 */
export interface ImportResponseDto {
  /**
   *
   * @type {number}
   * @memberof ImportResponseDto
   */
  nCreated?: number;
  /**
   *
   * @type {number}
   * @memberof ImportResponseDto
   */
  nUpdated?: number;
  /**
   *
   * @type {number}
   * @memberof ImportResponseDto
   */
  total: number;
}
/**
 *
 * @export
 * @interface NetworkInfoResponseDto
 */
export interface NetworkInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof NetworkInfoResponseDto
   */
  networkMode: NetworkInfoResponseDtoNetworkModeEnum;
  /**
   *
   * @type {number}
   * @memberof NetworkInfoResponseDto
   */
  networkSignalStrengthLevel: number;
}

export const NetworkInfoResponseDtoNetworkModeEnum = {
  Gsm: 'GSM',
  Gprs: 'GPRS',
  Edge: 'EDGE',
  Hsdpa: 'HSDPA',
  Lte: 'LTE',
} as const;

export type NetworkInfoResponseDtoNetworkModeEnum =
  (typeof NetworkInfoResponseDtoNetworkModeEnum)[keyof typeof NetworkInfoResponseDtoNetworkModeEnum];

/**
 *
 * @export
 * @interface PageMetaDto
 */
export interface PageMetaDto {
  /**
   *
   * @type {number}
   * @memberof PageMetaDto
   */
  skip: number;
  /**
   *
   * @type {number}
   * @memberof PageMetaDto
   */
  take: number;
  /**
   * Presents if `includeTotalCount` query parameter is true
   * @type {number}
   * @memberof PageMetaDto
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface PressureSampleValueDto
 */
export interface PressureSampleValueDto {
  /**
   *
   * @type {number}
   * @memberof PressureSampleValueDto
   */
  pressurePascals: number;
  /**
   *
   * @type {number}
   * @memberof PressureSampleValueDto
   */
  temperatureCelsiusDegrees: number;
}
/**
 *
 * @export
 * @interface RecordingDto
 */
export interface RecordingDto {
  /**
   *
   * @type {number}
   * @memberof RecordingDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  gatewayId: string;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  sensorId?: string;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  status: RecordingDtoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingDto
   */
  pressureDataRateHz?: RecordingDtoPressureDataRateHzEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingDto
   */
  accelerometerDataRateHz?: RecordingDtoAccelerometerDataRateHzEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingDto
   */
  accelerometerRange?: RecordingDtoAccelerometerRangeEnum;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  stoppedAt: string;
  /**
   *
   * @type {number}
   * @memberof RecordingDto
   */
  durationSec: number;
  /**
   *
   * @type {string}
   * @memberof RecordingDto
   */
  url: string;
}

export const RecordingDtoStatusEnum = {
  CommandSend: 'COMMAND_SEND',
  Started: 'STARTED',
  Stopped: 'STOPPED',
  Uploaded: 'UPLOADED',
} as const;

export type RecordingDtoStatusEnum =
  (typeof RecordingDtoStatusEnum)[keyof typeof RecordingDtoStatusEnum];
export const RecordingDtoPressureDataRateHzEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_10: 10,
  NUMBER_15: 15,
  NUMBER_20: 20,
  NUMBER_25: 25,
  NUMBER_30: 30,
  NUMBER_40: 40,
  NUMBER_45: 45,
  NUMBER_50: 50,
} as const;

export type RecordingDtoPressureDataRateHzEnum =
  (typeof RecordingDtoPressureDataRateHzEnum)[keyof typeof RecordingDtoPressureDataRateHzEnum];
export const RecordingDtoAccelerometerDataRateHzEnum = {
  NUMBER_12: 12,
  NUMBER_25: 25,
  NUMBER_50: 50,
  NUMBER_100: 100,
  NUMBER_200: 200,
} as const;

export type RecordingDtoAccelerometerDataRateHzEnum =
  (typeof RecordingDtoAccelerometerDataRateHzEnum)[keyof typeof RecordingDtoAccelerometerDataRateHzEnum];
export const RecordingDtoAccelerometerRangeEnum = {
  NUMBER_2: 2,
  NUMBER_4: 4,
  NUMBER_8: 8,
  NUMBER_16: 16,
} as const;

export type RecordingDtoAccelerometerRangeEnum =
  (typeof RecordingDtoAccelerometerRangeEnum)[keyof typeof RecordingDtoAccelerometerRangeEnum];

/**
 *
 * @export
 * @interface RecordingIdDto
 */
export interface RecordingIdDto {
  /**
   *
   * @type {number}
   * @memberof RecordingIdDto
   */
  recordingId: number;
}
/**
 *
 * @export
 * @interface RecordingStartedEventDto
 */
export interface RecordingStartedEventDto {
  /**
   *
   * @type {number}
   * @memberof RecordingStartedEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof RecordingStartedEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof RecordingStartedEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof RecordingStartedEventDto
   */
  type: RecordingStartedEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingStartedEventDto
   */
  recordingId: number;
  /**
   *
   * @type {string}
   * @memberof RecordingStartedEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof RecordingStartedEventDto
   */
  receivedAt: string;
}

export const RecordingStartedEventDtoTypeEnum = {
  RecordingStartedEvent: 'RECORDING_STARTED_EVENT',
} as const;

export type RecordingStartedEventDtoTypeEnum =
  (typeof RecordingStartedEventDtoTypeEnum)[keyof typeof RecordingStartedEventDtoTypeEnum];

/**
 *
 * @export
 * @interface RecordingStoppedEventDto
 */
export interface RecordingStoppedEventDto {
  /**
   *
   * @type {number}
   * @memberof RecordingStoppedEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof RecordingStoppedEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof RecordingStoppedEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof RecordingStoppedEventDto
   */
  type: RecordingStoppedEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingStoppedEventDto
   */
  recordingId: number;
  /**
   *
   * @type {string}
   * @memberof RecordingStoppedEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof RecordingStoppedEventDto
   */
  receivedAt: string;
}

export const RecordingStoppedEventDtoTypeEnum = {
  RecordingStoppedEvent: 'RECORDING_STOPPED_EVENT',
} as const;

export type RecordingStoppedEventDtoTypeEnum =
  (typeof RecordingStoppedEventDtoTypeEnum)[keyof typeof RecordingStoppedEventDtoTypeEnum];

/**
 *
 * @export
 * @interface RecordingsPageDto
 */
export interface RecordingsPageDto {
  /**
   *
   * @type {Array<RecordingDto>}
   * @memberof RecordingsPageDto
   */
  items: Array<RecordingDto>;
  /**
   *
   * @type {PageMetaDto}
   * @memberof RecordingsPageDto
   */
  meta: PageMetaDto;
}
/**
 *
 * @export
 * @interface RefPressureUpdateEventDto
 */
export interface RefPressureUpdateEventDto {
  /**
   *
   * @type {number}
   * @memberof RefPressureUpdateEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  type: RefPressureUpdateEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RefPressureUpdateEventDto
   */
  pressurePascals: number;
  /**
   *
   * @type {number}
   * @memberof RefPressureUpdateEventDto
   */
  temperatureCelsiusDegrees: number;
  /**
   *
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  sensorId: string;
  /**
   *
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof RefPressureUpdateEventDto
   */
  receivedAt: string;
}

export const RefPressureUpdateEventDtoTypeEnum = {
  RefPressureUpdateEvent: 'REF_PRESSURE_UPDATE_EVENT',
} as const;

export type RefPressureUpdateEventDtoTypeEnum =
  (typeof RefPressureUpdateEventDtoTypeEnum)[keyof typeof RefPressureUpdateEventDtoTypeEnum];

/**
 *
 * @export
 * @interface RefreshBatteryResponseDto
 */
export interface RefreshBatteryResponseDto {
  /**
   *
   * @type {string}
   * @memberof RefreshBatteryResponseDto
   */
  timestamp: string;
  /**
   *
   * @type {number}
   * @memberof RefreshBatteryResponseDto
   */
  door1SensorBatteryLevel?: number;
  /**
   *
   * @type {number}
   * @memberof RefreshBatteryResponseDto
   */
  door2SensorBatteryLevel?: number;
  /**
   *
   * @type {number}
   * @memberof RefreshBatteryResponseDto
   */
  referenceSensorBatteryLevel?: number;
}
/**
 *
 * @export
 * @interface RideEventDto
 */
export interface RideEventDto {
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof RideEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof RideEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof RideEventDto
   */
  type: RideEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  durationMillis: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  distanceMeters: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  pressureBeforePascals: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  pressureAfterPascals: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  temperatureAfterCelsiusDegrees: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  constantSpeedDurationMillis: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  accelerationDurationMillis?: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  decelerationDurationMillis?: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  accelerationPeakMPSS?: number;
  /**
   *
   * @type {number}
   * @memberof RideEventDto
   */
  decelerationPeakMPSS?: number;
  /**
   * Only available for ride events recognised on door sensor
   * @type {string}
   * @memberof RideEventDto
   */
  sensorId?: string;
  /**
   *
   * @type {string}
   * @memberof RideEventDto
   */
  direction: RideEventDtoDirectionEnum;
  /**
   *
   * @type {string}
   * @memberof RideEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof RideEventDto
   */
  receivedAt: string;
}

export const RideEventDtoTypeEnum = {
  RideEvent: 'RIDE_EVENT',
} as const;

export type RideEventDtoTypeEnum =
  (typeof RideEventDtoTypeEnum)[keyof typeof RideEventDtoTypeEnum];
export const RideEventDtoDirectionEnum = {
  Up: 'UP',
  Down: 'DOWN',
} as const;

export type RideEventDtoDirectionEnum =
  (typeof RideEventDtoDirectionEnum)[keyof typeof RideEventDtoDirectionEnum];

/**
 *
 * @export
 * @interface SensorBatteryEventDto
 */
export interface SensorBatteryEventDto {
  /**
   *
   * @type {number}
   * @memberof SensorBatteryEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  type: SensorBatteryEventDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SensorBatteryEventDto
   */
  batteryVoltageVolts: number;
  /**
   *
   * @type {number}
   * @memberof SensorBatteryEventDto
   */
  batteryPercentage: number;
  /**
   *
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  sensorId: string;
  /**
   *
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof SensorBatteryEventDto
   */
  receivedAt: string;
}

export const SensorBatteryEventDtoTypeEnum = {
  SensorBatteryEvent: 'SENSOR_BATTERY_EVENT',
} as const;

export type SensorBatteryEventDtoTypeEnum =
  (typeof SensorBatteryEventDtoTypeEnum)[keyof typeof SensorBatteryEventDtoTypeEnum];

/**
 *
 * @export
 * @interface SensorConnectionLostEventDto
 */
export interface SensorConnectionLostEventDto {
  /**
   *
   * @type {number}
   * @memberof SensorConnectionLostEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  type: SensorConnectionLostEventDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  sensorId: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  lastConnectionTime: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionLostEventDto
   */
  receivedAt: string;
}

export const SensorConnectionLostEventDtoTypeEnum = {
  SensorConnectionLostEvent: 'SENSOR_CONNECTION_LOST_EVENT',
} as const;

export type SensorConnectionLostEventDtoTypeEnum =
  (typeof SensorConnectionLostEventDtoTypeEnum)[keyof typeof SensorConnectionLostEventDtoTypeEnum];

/**
 *
 * @export
 * @interface SensorConnectionRecoveredEventDto
 */
export interface SensorConnectionRecoveredEventDto {
  /**
   *
   * @type {number}
   * @memberof SensorConnectionRecoveredEventDto
   */
  id: number;
  /**
   * An identifier of the gateway, which has emitted the event.
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  gatewayId: string;
  /**
   * External setup identifier assigned to a gateway, which has emitted the event at the time of emission.
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  setupId?: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  type: SensorConnectionRecoveredEventDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  sensorId: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  triggeredAt: string;
  /**
   *
   * @type {string}
   * @memberof SensorConnectionRecoveredEventDto
   */
  receivedAt: string;
}

export const SensorConnectionRecoveredEventDtoTypeEnum = {
  SensorConnectionRecoveredEvent: 'SENSOR_CONNECTION_RECOVERED_EVENT',
} as const;

export type SensorConnectionRecoveredEventDtoTypeEnum =
  (typeof SensorConnectionRecoveredEventDtoTypeEnum)[keyof typeof SensorConnectionRecoveredEventDtoTypeEnum];

/**
 *
 * @export
 * @interface SimCardDto
 */
export interface SimCardDto {
  /**
   * SIM card ICCID identifier
   * @type {string}
   * @memberof SimCardDto
   */
  simId: string;
  /**
   * SIM card PIN code
   * @type {string}
   * @memberof SimCardDto
   */
  pin?: string;
  /**
   *
   * @type {string}
   * @memberof SimCardDto
   */
  status: SimCardDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SimCardDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SimCardDto
   */
  activatedAt?: string;
}

export const SimCardDtoStatusEnum = {
  Active: 'ACTIVE',
  PendingActivation: 'PENDING_ACTIVATION',
  Fail: 'FAIL',
} as const;

export type SimCardDtoStatusEnum =
  (typeof SimCardDtoStatusEnum)[keyof typeof SimCardDtoStatusEnum];

/**
 *
 * @export
 * @interface StartRecordingCommandPayloadDto
 */
export interface StartRecordingCommandPayloadDto {
  /**
   *
   * @type {number}
   * @memberof StartRecordingCommandPayloadDto
   */
  durationSec: number;
  /**
   *
   * @type {string}
   * @memberof StartRecordingCommandPayloadDto
   */
  sensorId?: string;
  /**
   *
   * @type {number}
   * @memberof StartRecordingCommandPayloadDto
   */
  pressureDataRateHz?: StartRecordingCommandPayloadDtoPressureDataRateHzEnum;
  /**
   *
   * @type {number}
   * @memberof StartRecordingCommandPayloadDto
   */
  accelerometerDataRateHz?: StartRecordingCommandPayloadDtoAccelerometerDataRateHzEnum;
  /**
   *
   * @type {number}
   * @memberof StartRecordingCommandPayloadDto
   */
  accelerometerRange?: StartRecordingCommandPayloadDtoAccelerometerRangeEnum;
}

export const StartRecordingCommandPayloadDtoPressureDataRateHzEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_10: 10,
  NUMBER_15: 15,
  NUMBER_20: 20,
  NUMBER_25: 25,
  NUMBER_30: 30,
  NUMBER_40: 40,
  NUMBER_45: 45,
  NUMBER_50: 50,
} as const;

export type StartRecordingCommandPayloadDtoPressureDataRateHzEnum =
  (typeof StartRecordingCommandPayloadDtoPressureDataRateHzEnum)[keyof typeof StartRecordingCommandPayloadDtoPressureDataRateHzEnum];
export const StartRecordingCommandPayloadDtoAccelerometerDataRateHzEnum = {
  NUMBER_12: 12,
  NUMBER_25: 25,
  NUMBER_50: 50,
  NUMBER_100: 100,
  NUMBER_200: 200,
} as const;

export type StartRecordingCommandPayloadDtoAccelerometerDataRateHzEnum =
  (typeof StartRecordingCommandPayloadDtoAccelerometerDataRateHzEnum)[keyof typeof StartRecordingCommandPayloadDtoAccelerometerDataRateHzEnum];
export const StartRecordingCommandPayloadDtoAccelerometerRangeEnum = {
  NUMBER_2: 2,
  NUMBER_4: 4,
  NUMBER_8: 8,
  NUMBER_16: 16,
} as const;

export type StartRecordingCommandPayloadDtoAccelerometerRangeEnum =
  (typeof StartRecordingCommandPayloadDtoAccelerometerRangeEnum)[keyof typeof StartRecordingCommandPayloadDtoAccelerometerRangeEnum];

/**
 *
 * @export
 * @interface StartRecordingCommandResponseDto
 */
export interface StartRecordingCommandResponseDto {
  /**
   *
   * @type {number}
   * @memberof StartRecordingCommandResponseDto
   */
  recordingId: number;
  /**
   *
   * @type {CommonCommandResponsePayloadDto}
   * @memberof StartRecordingCommandResponseDto
   */
  gatewayResponse: CommonCommandResponsePayloadDto;
}
/**
 *
 * @export
 * @interface SwitchEnvCommandRequestPayloadDto
 */
export interface SwitchEnvCommandRequestPayloadDto {
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  hubId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  mqttBrokerUrl: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  mqttClientId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  mqttUsername: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  mqttPassword: string;
  /**
   *
   * @type {string}
   * @memberof SwitchEnvCommandRequestPayloadDto
   */
  firmwareApiUrl: string;
}
/**
 *
 * @export
 * @interface SwitchGwEnvInputDto
 */
export interface SwitchGwEnvInputDto {
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  mqttBrokerUrl: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  hubId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  mqttClientId: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  mqttUsername: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  mqttPassword: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  firmwareApiUrl: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  hcApiUrl: string;
  /**
   *
   * @type {string}
   * @memberof SwitchGwEnvInputDto
   */
  hcApiToken: string;
}

/**
 * APITokensApi - axios parameter creator
 * @export
 */
export const APITokensApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {ApiTokenCreateInputDto} apiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiToken: async (
      apiTokenCreateInputDto: ApiTokenCreateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTokenCreateInputDto' is not null or undefined
      assertParamExists(
        'createApiToken',
        'apiTokenCreateInputDto',
        apiTokenCreateInputDto,
      );
      const localVarPath = `/api/api-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apiTokenCreateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiToken: async (
      apiTokenId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTokenId' is not null or undefined
      assertParamExists('deleteApiToken', 'apiTokenId', apiTokenId);
      const localVarPath = `/api/api-tokens/{apiTokenId}`.replace(
        `{${'apiTokenId'}}`,
        encodeURIComponent(String(apiTokenId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiToken: async (
      apiTokenId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiTokenId' is not null or undefined
      assertParamExists('getApiToken', 'apiTokenId', apiTokenId);
      const localVarPath = `/api/api-tokens/{apiTokenId}`.replace(
        `{${'apiTokenId'}}`,
        encodeURIComponent(String(apiTokenId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiTokens: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/api-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-backoffice-api-key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-backoffice-api-key',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * APITokensApi - functional programming interface
 * @export
 */
export const APITokensApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    APITokensApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {ApiTokenCreateInputDto} apiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApiToken(
      apiTokenCreateInputDto: ApiTokenCreateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTokenDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createApiToken(
        apiTokenCreateInputDto,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['APITokensApi.createApiToken']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApiToken(
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiToken(
        apiTokenId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['APITokensApi.deleteApiToken']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiToken(
      apiTokenId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTokenDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiToken(
        apiTokenId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['APITokensApi.getApiToken']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiTokens(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApiTokenDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApiTokens(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['APITokensApi.getApiTokens']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * APITokensApi - factory interface
 * @export
 */
export const APITokensApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = APITokensApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {ApiTokenCreateInputDto} apiTokenCreateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiToken(
      apiTokenCreateInputDto: ApiTokenCreateInputDto,
      options?: any,
    ): AxiosPromise<ApiTokenDto> {
      return localVarFp
        .createApiToken(apiTokenCreateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiToken(apiTokenId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteApiToken(apiTokenId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} apiTokenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiToken(apiTokenId: string, options?: any): AxiosPromise<ApiTokenDto> {
      return localVarFp
        .getApiToken(apiTokenId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiTokens(options?: any): AxiosPromise<Array<ApiTokenDto>> {
      return localVarFp
        .getApiTokens(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * APITokensApi - object-oriented interface
 * @export
 * @class APITokensApi
 * @extends {BaseAPI}
 */
export class APITokensApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {ApiTokenCreateInputDto} apiTokenCreateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APITokensApi
   */
  public createApiToken(
    apiTokenCreateInputDto: ApiTokenCreateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return APITokensApiFp(this.configuration)
      .createApiToken(apiTokenCreateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} apiTokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APITokensApi
   */
  public deleteApiToken(apiTokenId: string, options?: RawAxiosRequestConfig) {
    return APITokensApiFp(this.configuration)
      .deleteApiToken(apiTokenId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} apiTokenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APITokensApi
   */
  public getApiToken(apiTokenId: string, options?: RawAxiosRequestConfig) {
    return APITokensApiFp(this.configuration)
      .getApiToken(apiTokenId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APITokensApi
   */
  public getApiTokens(options?: RawAxiosRequestConfig) {
    return APITokensApiFp(this.configuration)
      .getApiTokens(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AlflexGatewaysApi - axios parameter creator
 * @export
 */
export const AlflexGatewaysApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary [Debug purpose endpoint] Delete all Alflex gateways
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllAlflexGateways: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/alflex-gateways`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * File should contain an array of AlflexGatewayDto objects       and should be in JSON format          [{            \"gatewayId\": \"string\",            \"door1SensorId\": \"door1\",            \"door2SensorId\": \"door3\",            \"referenceSensorId\": \"ref\",            \"description\": \"gateway setup\",          }]
     * @summary Imports Alflex Gateways as it is from the file and skip existing ones
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importAlflexGateways: async (
      file: any,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('importAlflexGateways', 'file', file);
      const localVarPath = `/api/alflex-gateways/import`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append(
          'file',
          new Blob([JSON.stringify(file)], { type: 'application/json' }),
        );
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AlflexGatewaysApi - functional programming interface
 * @export
 */
export const AlflexGatewaysApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AlflexGatewaysApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary [Debug purpose endpoint] Delete all Alflex gateways
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAllAlflexGateways(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAllAlflexGateways(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AlflexGatewaysApi.deleteAllAlflexGateways']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * File should contain an array of AlflexGatewayDto objects       and should be in JSON format          [{            \"gatewayId\": \"string\",            \"door1SensorId\": \"door1\",            \"door2SensorId\": \"door3\",            \"referenceSensorId\": \"ref\",            \"description\": \"gateway setup\",          }]
     * @summary Imports Alflex Gateways as it is from the file and skip existing ones
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importAlflexGateways(
      file: any,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importAlflexGateways(file, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AlflexGatewaysApi.importAlflexGateways']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * AlflexGatewaysApi - factory interface
 * @export
 */
export const AlflexGatewaysApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AlflexGatewaysApiFp(configuration);
  return {
    /**
     *
     * @summary [Debug purpose endpoint] Delete all Alflex gateways
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllAlflexGateways(options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteAllAlflexGateways(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * File should contain an array of AlflexGatewayDto objects       and should be in JSON format          [{            \"gatewayId\": \"string\",            \"door1SensorId\": \"door1\",            \"door2SensorId\": \"door3\",            \"referenceSensorId\": \"ref\",            \"description\": \"gateway setup\",          }]
     * @summary Imports Alflex Gateways as it is from the file and skip existing ones
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importAlflexGateways(
      file: any,
      options?: any,
    ): AxiosPromise<ImportResponseDto> {
      return localVarFp
        .importAlflexGateways(file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AlflexGatewaysApi - object-oriented interface
 * @export
 * @class AlflexGatewaysApi
 * @extends {BaseAPI}
 */
export class AlflexGatewaysApi extends BaseAPI {
  /**
   *
   * @summary [Debug purpose endpoint] Delete all Alflex gateways
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlflexGatewaysApi
   */
  public deleteAllAlflexGateways(options?: RawAxiosRequestConfig) {
    return AlflexGatewaysApiFp(this.configuration)
      .deleteAllAlflexGateways(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * File should contain an array of AlflexGatewayDto objects       and should be in JSON format          [{            \"gatewayId\": \"string\",            \"door1SensorId\": \"door1\",            \"door2SensorId\": \"door3\",            \"referenceSensorId\": \"ref\",            \"description\": \"gateway setup\",          }]
   * @summary Imports Alflex Gateways as it is from the file and skip existing ones
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlflexGatewaysApi
   */
  public importAlflexGateways(file: any, options?: RawAxiosRequestConfig) {
    return AlflexGatewaysApiFp(this.configuration)
      .importAlflexGateways(file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * D2CFWImagesApi - axios parameter creator
 * @export
 */
export const D2CFWImagesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {GetD2CFwImageByPasswordTypeEnum} type
     * @param {string} version
     * @param {string} xDevId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getD2CFwImageByPassword: async (
      type: GetD2CFwImageByPasswordTypeEnum,
      version: string,
      xDevId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getD2CFwImageByPassword', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('getD2CFwImageByPassword', 'version', version);
      // verify required parameter 'xDevId' is not null or undefined
      assertParamExists('getD2CFwImageByPassword', 'xDevId', xDevId);
      const localVarPath = `/api/d2c/fw-images/{type}/{version}/by-password`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication x-dev-pwd required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-dev-pwd',
        configuration,
      );

      if (xDevId != null) {
        localVarHeaderParameter['x-dev-id'] = String(xDevId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {GetD2CFwImageByTokenTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getD2CFwImageByToken: async (
      type: GetD2CFwImageByTokenTypeEnum,
      version: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getD2CFwImageByToken', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('getD2CFwImageByToken', 'version', version);
      const localVarPath = `/api/d2c/fw-images/{type}/{version}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * D2CFWImagesApi - functional programming interface
 * @export
 */
export const D2CFWImagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    D2CFWImagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {GetD2CFwImageByPasswordTypeEnum} type
     * @param {string} version
     * @param {string} xDevId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getD2CFwImageByPassword(
      type: GetD2CFwImageByPasswordTypeEnum,
      version: string,
      xDevId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<D2cFwImageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getD2CFwImageByPassword(
          type,
          version,
          xDevId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['D2CFWImagesApi.getD2CFwImageByPassword']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {GetD2CFwImageByTokenTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getD2CFwImageByToken(
      type: GetD2CFwImageByTokenTypeEnum,
      version: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<D2cFwImageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getD2CFwImageByToken(
          type,
          version,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['D2CFWImagesApi.getD2CFwImageByToken']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * D2CFWImagesApi - factory interface
 * @export
 */
export const D2CFWImagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = D2CFWImagesApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {GetD2CFwImageByPasswordTypeEnum} type
     * @param {string} version
     * @param {string} xDevId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getD2CFwImageByPassword(
      type: GetD2CFwImageByPasswordTypeEnum,
      version: string,
      xDevId: string,
      options?: any,
    ): AxiosPromise<D2cFwImageDto> {
      return localVarFp
        .getD2CFwImageByPassword(type, version, xDevId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {GetD2CFwImageByTokenTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getD2CFwImageByToken(
      type: GetD2CFwImageByTokenTypeEnum,
      version: string,
      options?: any,
    ): AxiosPromise<D2cFwImageDto> {
      return localVarFp
        .getD2CFwImageByToken(type, version, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * D2CFWImagesApi - object-oriented interface
 * @export
 * @class D2CFWImagesApi
 * @extends {BaseAPI}
 */
export class D2CFWImagesApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {GetD2CFwImageByPasswordTypeEnum} type
   * @param {string} version
   * @param {string} xDevId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof D2CFWImagesApi
   */
  public getD2CFwImageByPassword(
    type: GetD2CFwImageByPasswordTypeEnum,
    version: string,
    xDevId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return D2CFWImagesApiFp(this.configuration)
      .getD2CFwImageByPassword(type, version, xDevId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {GetD2CFwImageByTokenTypeEnum} type
   * @param {string} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof D2CFWImagesApi
   */
  public getD2CFwImageByToken(
    type: GetD2CFwImageByTokenTypeEnum,
    version: string,
    options?: RawAxiosRequestConfig,
  ) {
    return D2CFWImagesApiFp(this.configuration)
      .getD2CFwImageByToken(type, version, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetD2CFwImageByPasswordTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type GetD2CFwImageByPasswordTypeEnum =
  (typeof GetD2CFwImageByPasswordTypeEnum)[keyof typeof GetD2CFwImageByPasswordTypeEnum];
/**
 * @export
 */
export const GetD2CFwImageByTokenTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type GetD2CFwImageByTokenTypeEnum =
  (typeof GetD2CFwImageByTokenTypeEnum)[keyof typeof GetD2CFwImageByTokenTypeEnum];

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {DateFilterDto} [triggeredAt] Filter events by triggered date.
     * @param {Array<GetEventsOrderByEnum>} [orderBy] Order events.
     * @param {Array<GetEventsEventTypeEnum>} [eventType] Filter events by event type.
     * @param {Array<string>} [gatewaysIds] Filter events by source gateways identifiers, multiple gateways identifiers can be provided.
     * @param {Array<string>} [setupIds] Filter events by the setup identifier. Different gateways could be used for the same setup at different time frames. Multiple setup identifiers be provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvents: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      triggeredAt?: DateFilterDto,
      orderBy?: Array<GetEventsOrderByEnum>,
      eventType?: Array<GetEventsEventTypeEnum>,
      gatewaysIds?: Array<string>,
      setupIds?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/events`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (triggeredAt !== undefined) {
        for (const [key, value] of Object.entries(triggeredAt)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (orderBy) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (eventType) {
        localVarQueryParameter['eventType'] = eventType;
      }

      if (gatewaysIds) {
        localVarQueryParameter['gatewaysIds'] = gatewaysIds;
      }

      if (setupIds) {
        localVarQueryParameter['setupIds'] = setupIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {DateFilterDto} [triggeredAt] Filter events by triggered date.
     * @param {Array<GetEventsOrderByEnum>} [orderBy] Order events.
     * @param {Array<GetEventsEventTypeEnum>} [eventType] Filter events by event type.
     * @param {Array<string>} [gatewaysIds] Filter events by source gateways identifiers, multiple gateways identifiers can be provided.
     * @param {Array<string>} [setupIds] Filter events by the setup identifier. Different gateways could be used for the same setup at different time frames. Multiple setup identifiers be provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvents(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      triggeredAt?: DateFilterDto,
      orderBy?: Array<GetEventsOrderByEnum>,
      eventType?: Array<GetEventsEventTypeEnum>,
      gatewaysIds?: Array<string>,
      setupIds?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(
        skip,
        take,
        includeTotalCount,
        triggeredAt,
        orderBy,
        eventType,
        gatewaysIds,
        setupIds,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['EventsApi.getEvents']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EventsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {DateFilterDto} [triggeredAt] Filter events by triggered date.
     * @param {Array<GetEventsOrderByEnum>} [orderBy] Order events.
     * @param {Array<GetEventsEventTypeEnum>} [eventType] Filter events by event type.
     * @param {Array<string>} [gatewaysIds] Filter events by source gateways identifiers, multiple gateways identifiers can be provided.
     * @param {Array<string>} [setupIds] Filter events by the setup identifier. Different gateways could be used for the same setup at different time frames. Multiple setup identifiers be provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvents(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      triggeredAt?: DateFilterDto,
      orderBy?: Array<GetEventsOrderByEnum>,
      eventType?: Array<GetEventsEventTypeEnum>,
      gatewaysIds?: Array<string>,
      setupIds?: Array<string>,
      options?: any,
    ): AxiosPromise<EventsPageDto> {
      return localVarFp
        .getEvents(
          skip,
          take,
          includeTotalCount,
          triggeredAt,
          orderBy,
          eventType,
          gatewaysIds,
          setupIds,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {DateFilterDto} [triggeredAt] Filter events by triggered date.
   * @param {Array<GetEventsOrderByEnum>} [orderBy] Order events.
   * @param {Array<GetEventsEventTypeEnum>} [eventType] Filter events by event type.
   * @param {Array<string>} [gatewaysIds] Filter events by source gateways identifiers, multiple gateways identifiers can be provided.
   * @param {Array<string>} [setupIds] Filter events by the setup identifier. Different gateways could be used for the same setup at different time frames. Multiple setup identifiers be provided.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEvents(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    triggeredAt?: DateFilterDto,
    orderBy?: Array<GetEventsOrderByEnum>,
    eventType?: Array<GetEventsEventTypeEnum>,
    gatewaysIds?: Array<string>,
    setupIds?: Array<string>,
    options?: RawAxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .getEvents(
        skip,
        take,
        includeTotalCount,
        triggeredAt,
        orderBy,
        eventType,
        gatewaysIds,
        setupIds,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetEventsOrderByEnum = {
  Asc: 'triggeredAt[asc]',
  Desc: 'triggeredAt[desc]',
} as const;
export type GetEventsOrderByEnum =
  (typeof GetEventsOrderByEnum)[keyof typeof GetEventsOrderByEnum];
/**
 * @export
 */
export const GetEventsEventTypeEnum = {
  DoorEvent: 'DOOR_EVENT',
  RideEvent: 'RIDE_EVENT',
  SensorBatteryEvent: 'SENSOR_BATTERY_EVENT',
  RefPressureUpdateEvent: 'REF_PRESSURE_UPDATE_EVENT',
  GwPowerLostEvent: 'GW_POWER_LOST_EVENT',
  SensorConnectionLostEvent: 'SENSOR_CONNECTION_LOST_EVENT',
  SensorConnectionRecoveredEvent: 'SENSOR_CONNECTION_RECOVERED_EVENT',
  RecordingStartedEvent: 'RECORDING_STARTED_EVENT',
  RecordingStoppedEvent: 'RECORDING_STOPPED_EVENT',
} as const;
export type GetEventsEventTypeEnum =
  (typeof GetEventsEventTypeEnum)[keyof typeof GetEventsEventTypeEnum];

/**
 * FWImagesApi - axios parameter creator
 * @export
 */
export const FWImagesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {DeleteFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFwImage: async (
      type: DeleteFwImageTypeEnum,
      version: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('deleteFwImage', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('deleteFwImage', 'version', version);
      const localVarPath = `/api/fw-images/{type}/{version}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {GetFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFwImage: async (
      type: GetFwImageTypeEnum,
      version: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getFwImage', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('getFwImage', 'version', version);
      const localVarPath = `/api/fw-images/{type}/{version}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {GetFwImagesTypeEnum} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFwImages: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      type?: GetFwImagesTypeEnum,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/fw-images`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {PutFwImageTypeEnum} type
     * @param {string} version
     * @param {File} file
     * @param {string} [description]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putFwImage: async (
      type: PutFwImageTypeEnum,
      version: string,
      file: File,
      description?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('putFwImage', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('putFwImage', 'version', version);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('putFwImage', 'file', file);
      const localVarPath = `/api/fw-images/{type}/{version}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {UpdateFwImageTypeEnum} type
     * @param {string} version
     * @param {FwImageUpdateInputDto} fwImageUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFwImage: async (
      type: UpdateFwImageTypeEnum,
      version: string,
      fwImageUpdateInputDto: FwImageUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('updateFwImage', 'type', type);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('updateFwImage', 'version', version);
      // verify required parameter 'fwImageUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateFwImage',
        'fwImageUpdateInputDto',
        fwImageUpdateInputDto,
      );
      const localVarPath = `/api/fw-images/{type}/{version}`
        .replace(`{${'type'}}`, encodeURIComponent(String(type)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fwImageUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FWImagesApi - functional programming interface
 * @export
 */
export const FWImagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FWImagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {DeleteFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFwImage(
      type: DeleteFwImageTypeEnum,
      version: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFwImage(
        type,
        version,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['FWImagesApi.deleteFwImage']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {GetFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFwImage(
      type: GetFwImageTypeEnum,
      version: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FwImageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFwImage(
        type,
        version,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['FWImagesApi.getFwImage']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {GetFwImagesTypeEnum} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFwImages(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      type?: GetFwImagesTypeEnum,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FwImagesPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFwImages(
        skip,
        take,
        includeTotalCount,
        type,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['FWImagesApi.getFwImages']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {PutFwImageTypeEnum} type
     * @param {string} version
     * @param {File} file
     * @param {string} [description]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putFwImage(
      type: PutFwImageTypeEnum,
      version: string,
      file: File,
      description?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FwImageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putFwImage(
        type,
        version,
        file,
        description,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['FWImagesApi.putFwImage']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {UpdateFwImageTypeEnum} type
     * @param {string} version
     * @param {FwImageUpdateInputDto} fwImageUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFwImage(
      type: UpdateFwImageTypeEnum,
      version: string,
      fwImageUpdateInputDto: FwImageUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FwImageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFwImage(
        type,
        version,
        fwImageUpdateInputDto,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['FWImagesApi.updateFwImage']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * FWImagesApi - factory interface
 * @export
 */
export const FWImagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FWImagesApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {DeleteFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFwImage(
      type: DeleteFwImageTypeEnum,
      version: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFwImage(type, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {GetFwImageTypeEnum} type
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFwImage(
      type: GetFwImageTypeEnum,
      version: string,
      options?: any,
    ): AxiosPromise<FwImageDto> {
      return localVarFp
        .getFwImage(type, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {GetFwImagesTypeEnum} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFwImages(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      type?: GetFwImagesTypeEnum,
      options?: any,
    ): AxiosPromise<FwImagesPageDto> {
      return localVarFp
        .getFwImages(skip, take, includeTotalCount, type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {PutFwImageTypeEnum} type
     * @param {string} version
     * @param {File} file
     * @param {string} [description]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putFwImage(
      type: PutFwImageTypeEnum,
      version: string,
      file: File,
      description?: string,
      options?: any,
    ): AxiosPromise<FwImageDto> {
      return localVarFp
        .putFwImage(type, version, file, description, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {UpdateFwImageTypeEnum} type
     * @param {string} version
     * @param {FwImageUpdateInputDto} fwImageUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFwImage(
      type: UpdateFwImageTypeEnum,
      version: string,
      fwImageUpdateInputDto: FwImageUpdateInputDto,
      options?: any,
    ): AxiosPromise<FwImageDto> {
      return localVarFp
        .updateFwImage(type, version, fwImageUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FWImagesApi - object-oriented interface
 * @export
 * @class FWImagesApi
 * @extends {BaseAPI}
 */
export class FWImagesApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {DeleteFwImageTypeEnum} type
   * @param {string} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FWImagesApi
   */
  public deleteFwImage(
    type: DeleteFwImageTypeEnum,
    version: string,
    options?: RawAxiosRequestConfig,
  ) {
    return FWImagesApiFp(this.configuration)
      .deleteFwImage(type, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {GetFwImageTypeEnum} type
   * @param {string} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FWImagesApi
   */
  public getFwImage(
    type: GetFwImageTypeEnum,
    version: string,
    options?: RawAxiosRequestConfig,
  ) {
    return FWImagesApiFp(this.configuration)
      .getFwImage(type, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {GetFwImagesTypeEnum} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FWImagesApi
   */
  public getFwImages(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    type?: GetFwImagesTypeEnum,
    options?: RawAxiosRequestConfig,
  ) {
    return FWImagesApiFp(this.configuration)
      .getFwImages(skip, take, includeTotalCount, type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {PutFwImageTypeEnum} type
   * @param {string} version
   * @param {File} file
   * @param {string} [description]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FWImagesApi
   */
  public putFwImage(
    type: PutFwImageTypeEnum,
    version: string,
    file: File,
    description?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return FWImagesApiFp(this.configuration)
      .putFwImage(type, version, file, description, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {UpdateFwImageTypeEnum} type
   * @param {string} version
   * @param {FwImageUpdateInputDto} fwImageUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FWImagesApi
   */
  public updateFwImage(
    type: UpdateFwImageTypeEnum,
    version: string,
    fwImageUpdateInputDto: FwImageUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return FWImagesApiFp(this.configuration)
      .updateFwImage(type, version, fwImageUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const DeleteFwImageTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type DeleteFwImageTypeEnum =
  (typeof DeleteFwImageTypeEnum)[keyof typeof DeleteFwImageTypeEnum];
/**
 * @export
 */
export const GetFwImageTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type GetFwImageTypeEnum =
  (typeof GetFwImageTypeEnum)[keyof typeof GetFwImageTypeEnum];
/**
 * @export
 */
export const GetFwImagesTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type GetFwImagesTypeEnum =
  (typeof GetFwImagesTypeEnum)[keyof typeof GetFwImagesTypeEnum];
/**
 * @export
 */
export const PutFwImageTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type PutFwImageTypeEnum =
  (typeof PutFwImageTypeEnum)[keyof typeof PutFwImageTypeEnum];
/**
 * @export
 */
export const UpdateFwImageTypeEnum = {
  Gateway: 'gateway',
  Sensor: 'sensor',
} as const;
export type UpdateFwImageTypeEnum =
  (typeof UpdateFwImageTypeEnum)[keyof typeof UpdateFwImageTypeEnum];

/**
 * GatewayCommandsApi - axios parameter creator
 * @export
 */
export const GatewayCommandsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Sends a command with name:\"gtw_state\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gatewayStateCommand: async (
      gatewayId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('gatewayStateCommand', 'gatewayId', gatewayId);
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/gateway-state`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a command with name:\"network_info\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    networkInfoCommand: async (
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('networkInfoCommand', 'gatewayId', gatewayId);
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/network-info`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a command with name:\"refresh_sensors_battery_level\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshSensorsBatteryLevelCommand: async (
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists(
        'refreshSensorsBatteryLevelCommand',
        'gatewayId',
        gatewayId,
      );
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/refresh-battery`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a command with name:\"start_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {StartRecordingCommandPayloadDto} startRecordingCommandPayloadDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startRecordingCommand: async (
      gatewayId: string,
      startRecordingCommandPayloadDto: StartRecordingCommandPayloadDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('startRecordingCommand', 'gatewayId', gatewayId);
      // verify required parameter 'startRecordingCommandPayloadDto' is not null or undefined
      assertParamExists(
        'startRecordingCommand',
        'startRecordingCommandPayloadDto',
        startRecordingCommandPayloadDto,
      );
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/start-recording`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        startRecordingCommandPayloadDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a command with name:\"stop_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {RecordingIdDto} recordingIdDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopRecordingCommand: async (
      gatewayId: string,
      recordingIdDto: RecordingIdDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('stopRecordingCommand', 'gatewayId', gatewayId);
      // verify required parameter 'recordingIdDto' is not null or undefined
      assertParamExists(
        'stopRecordingCommand',
        'recordingIdDto',
        recordingIdDto,
      );
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/stop-recording`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recordingIdDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validates provided credentials (gateway should already exists on new env) and sends a command name:\"switch_gw_env\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchGwEnvInputDto} switchGwEnvInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchGatewayEnvCommand: async (
      gatewayId: string,
      switchGwEnvInputDto: SwitchGwEnvInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('switchGatewayEnvCommand', 'gatewayId', gatewayId);
      // verify required parameter 'switchGwEnvInputDto' is not null or undefined
      assertParamExists(
        'switchGatewayEnvCommand',
        'switchGwEnvInputDto',
        switchGwEnvInputDto,
      );
      const localVarPath =
        `/api/gateways/{gatewayId}/commands/switch-env`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timeoutSec !== undefined) {
        localVarQueryParameter['timeoutSec'] = timeoutSec;
      }

      if (maxNAttempts !== undefined) {
        localVarQueryParameter['maxNAttempts'] = maxNAttempts;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        switchGwEnvInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GatewayCommandsApi - functional programming interface
 * @export
 */
export const GatewayCommandsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GatewayCommandsApiAxiosParamCreator(configuration);
  return {
    /**
     * Sends a command with name:\"gtw_state\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gatewayStateCommand(
      gatewayId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GatewayStateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.gatewayStateCommand(gatewayId, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewayCommandsApi.gatewayStateCommand']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sends a command with name:\"network_info\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async networkInfoCommand(
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NetworkInfoResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.networkInfoCommand(
          gatewayId,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewayCommandsApi.networkInfoCommand']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sends a command with name:\"refresh_sensors_battery_level\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshSensorsBatteryLevelCommand(
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RefreshBatteryResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshSensorsBatteryLevelCommand(
          gatewayId,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          'GatewayCommandsApi.refreshSensorsBatteryLevelCommand'
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sends a command with name:\"start_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {StartRecordingCommandPayloadDto} startRecordingCommandPayloadDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startRecordingCommand(
      gatewayId: string,
      startRecordingCommandPayloadDto: StartRecordingCommandPayloadDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StartRecordingCommandResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startRecordingCommand(
          gatewayId,
          startRecordingCommandPayloadDto,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewayCommandsApi.startRecordingCommand']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sends a command with name:\"stop_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {RecordingIdDto} recordingIdDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopRecordingCommand(
      gatewayId: string,
      recordingIdDto: RecordingIdDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommonCommandResponsePayloadDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopRecordingCommand(
          gatewayId,
          recordingIdDto,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewayCommandsApi.stopRecordingCommand']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validates provided credentials (gateway should already exists on new env) and sends a command name:\"switch_gw_env\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchGwEnvInputDto} switchGwEnvInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchGatewayEnvCommand(
      gatewayId: string,
      switchGwEnvInputDto: SwitchGwEnvInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommonCommandResponsePayloadDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.switchGatewayEnvCommand(
          gatewayId,
          switchGwEnvInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewayCommandsApi.switchGatewayEnvCommand']?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * GatewayCommandsApi - factory interface
 * @export
 */
export const GatewayCommandsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GatewayCommandsApiFp(configuration);
  return {
    /**
     * Sends a command with name:\"gtw_state\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gatewayStateCommand(
      gatewayId: string,
      options?: any,
    ): AxiosPromise<GatewayStateResponseDto> {
      return localVarFp
        .gatewayStateCommand(gatewayId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a command with name:\"network_info\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    networkInfoCommand(
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: any,
    ): AxiosPromise<NetworkInfoResponseDto> {
      return localVarFp
        .networkInfoCommand(gatewayId, timeoutSec, maxNAttempts, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a command with name:\"refresh_sensors_battery_level\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshSensorsBatteryLevelCommand(
      gatewayId: string,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: any,
    ): AxiosPromise<RefreshBatteryResponseDto> {
      return localVarFp
        .refreshSensorsBatteryLevelCommand(
          gatewayId,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a command with name:\"start_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {StartRecordingCommandPayloadDto} startRecordingCommandPayloadDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startRecordingCommand(
      gatewayId: string,
      startRecordingCommandPayloadDto: StartRecordingCommandPayloadDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: any,
    ): AxiosPromise<StartRecordingCommandResponseDto> {
      return localVarFp
        .startRecordingCommand(
          gatewayId,
          startRecordingCommandPayloadDto,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a command with name:\"stop_recording\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {RecordingIdDto} recordingIdDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopRecordingCommand(
      gatewayId: string,
      recordingIdDto: RecordingIdDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: any,
    ): AxiosPromise<CommonCommandResponsePayloadDto> {
      return localVarFp
        .stopRecordingCommand(
          gatewayId,
          recordingIdDto,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates provided credentials (gateway should already exists on new env) and sends a command name:\"switch_gw_env\" to iot-core service
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchGwEnvInputDto} switchGwEnvInputDto
     * @param {number} [timeoutSec] Min value: 1, max value: 60
     * @param {number} [maxNAttempts] Min value: 1, max value: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchGatewayEnvCommand(
      gatewayId: string,
      switchGwEnvInputDto: SwitchGwEnvInputDto,
      timeoutSec?: number,
      maxNAttempts?: number,
      options?: any,
    ): AxiosPromise<CommonCommandResponsePayloadDto> {
      return localVarFp
        .switchGatewayEnvCommand(
          gatewayId,
          switchGwEnvInputDto,
          timeoutSec,
          maxNAttempts,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GatewayCommandsApi - object-oriented interface
 * @export
 * @class GatewayCommandsApi
 * @extends {BaseAPI}
 */
export class GatewayCommandsApi extends BaseAPI {
  /**
   * Sends a command with name:\"gtw_state\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public gatewayStateCommand(
    gatewayId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .gatewayStateCommand(gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a command with name:\"network_info\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public networkInfoCommand(
    gatewayId: string,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .networkInfoCommand(gatewayId, timeoutSec, maxNAttempts, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a command with name:\"refresh_sensors_battery_level\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public refreshSensorsBatteryLevelCommand(
    gatewayId: string,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .refreshSensorsBatteryLevelCommand(
        gatewayId,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a command with name:\"start_recording\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {StartRecordingCommandPayloadDto} startRecordingCommandPayloadDto
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public startRecordingCommand(
    gatewayId: string,
    startRecordingCommandPayloadDto: StartRecordingCommandPayloadDto,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .startRecordingCommand(
        gatewayId,
        startRecordingCommandPayloadDto,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a command with name:\"stop_recording\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {RecordingIdDto} recordingIdDto
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public stopRecordingCommand(
    gatewayId: string,
    recordingIdDto: RecordingIdDto,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .stopRecordingCommand(
        gatewayId,
        recordingIdDto,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates provided credentials (gateway should already exists on new env) and sends a command name:\"switch_gw_env\" to iot-core service
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {SwitchGwEnvInputDto} switchGwEnvInputDto
   * @param {number} [timeoutSec] Min value: 1, max value: 60
   * @param {number} [maxNAttempts] Min value: 1, max value: 10
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewayCommandsApi
   */
  public switchGatewayEnvCommand(
    gatewayId: string,
    switchGwEnvInputDto: SwitchGwEnvInputDto,
    timeoutSec?: number,
    maxNAttempts?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewayCommandsApiFp(this.configuration)
      .switchGatewayEnvCommand(
        gatewayId,
        switchGwEnvInputDto,
        timeoutSec,
        maxNAttempts,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GatewaysApi - axios parameter creator
 * @export
 */
export const GatewaysApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGateway: async (
      gatewayId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('deleteGateway', 'gatewayId', gatewayId);
      const localVarPath = `/api/gateways/{gatewayId}`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGateway: async (
      gatewayId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('getGateway', 'gatewayId', gatewayId);
      const localVarPath = `/api/gateways/{gatewayId}`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [search] Description/id text partial match case insensitive search
     * @param {Array<string>} [tags]
     * @param {string} [setupId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGateways: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      search?: string,
      tags?: Array<string>,
      setupId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/gateways`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      if (setupId !== undefined) {
        localVarQueryParameter['setupId'] = setupId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchGateway: async (
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('patchGateway', 'gatewayId', gatewayId);
      // verify required parameter 'gatewayUpdateInputDto' is not null or undefined
      assertParamExists(
        'patchGateway',
        'gatewayUpdateInputDto',
        gatewayUpdateInputDto,
      );
      const localVarPath = `/api/gateways/{gatewayId}`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        gatewayUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putGateway: async (
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('putGateway', 'gatewayId', gatewayId);
      // verify required parameter 'gatewayUpdateInputDto' is not null or undefined
      assertParamExists(
        'putGateway',
        'gatewayUpdateInputDto',
        gatewayUpdateInputDto,
      );
      const localVarPath = `/api/gateways/{gatewayId}`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        gatewayUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchEnvCommandRequestPayloadDto} switchEnvCommandRequestPayloadDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateGatewayCredentials: async (
      gatewayId: string,
      switchEnvCommandRequestPayloadDto: SwitchEnvCommandRequestPayloadDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('validateGatewayCredentials', 'gatewayId', gatewayId);
      // verify required parameter 'switchEnvCommandRequestPayloadDto' is not null or undefined
      assertParamExists(
        'validateGatewayCredentials',
        'switchEnvCommandRequestPayloadDto',
        switchEnvCommandRequestPayloadDto,
      );
      const localVarPath =
        `/api/gateways/{gatewayId}/credentials/verify`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(gatewayId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        switchEnvCommandRequestPayloadDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GatewaysApi - functional programming interface
 * @export
 */
export const GatewaysApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GatewaysApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGateway(
      gatewayId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGateway(
        gatewayId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.deleteGateway']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGateway(
      gatewayId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGateway(
        gatewayId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.getGateway']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [search] Description/id text partial match case insensitive search
     * @param {Array<string>} [tags]
     * @param {string} [setupId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGateways(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      search?: string,
      tags?: Array<string>,
      setupId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GatewaysPageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGateways(
        skip,
        take,
        includeTotalCount,
        search,
        tags,
        setupId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.getGateways']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchGateway(
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchGateway(
        gatewayId,
        gatewayUpdateInputDto,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.patchGateway']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putGateway(
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putGateway(
        gatewayId,
        gatewayUpdateInputDto,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.putGateway']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchEnvCommandRequestPayloadDto} switchEnvCommandRequestPayloadDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateGatewayCredentials(
      gatewayId: string,
      switchEnvCommandRequestPayloadDto: SwitchEnvCommandRequestPayloadDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ActionResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateGatewayCredentials(
          gatewayId,
          switchEnvCommandRequestPayloadDto,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysApi.validateGatewayCredentials']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * GatewaysApi - factory interface
 * @export
 */
export const GatewaysApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GatewaysApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGateway(gatewayId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteGateway(gatewayId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGateway(gatewayId: string, options?: any): AxiosPromise<GatewayDto> {
      return localVarFp
        .getGateway(gatewayId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [search] Description/id text partial match case insensitive search
     * @param {Array<string>} [tags]
     * @param {string} [setupId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGateways(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      search?: string,
      tags?: Array<string>,
      setupId?: string,
      options?: any,
    ): AxiosPromise<GatewaysPageDto> {
      return localVarFp
        .getGateways(
          skip,
          take,
          includeTotalCount,
          search,
          tags,
          setupId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchGateway(
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options?: any,
    ): AxiosPromise<GatewayDto> {
      return localVarFp
        .patchGateway(gatewayId, gatewayUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putGateway(
      gatewayId: string,
      gatewayUpdateInputDto: GatewayUpdateInputDto,
      options?: any,
    ): AxiosPromise<GatewayDto> {
      return localVarFp
        .putGateway(gatewayId, gatewayUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {SwitchEnvCommandRequestPayloadDto} switchEnvCommandRequestPayloadDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateGatewayCredentials(
      gatewayId: string,
      switchEnvCommandRequestPayloadDto: SwitchEnvCommandRequestPayloadDto,
      options?: any,
    ): AxiosPromise<ActionResultDto> {
      return localVarFp
        .validateGatewayCredentials(
          gatewayId,
          switchEnvCommandRequestPayloadDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GatewaysApi - object-oriented interface
 * @export
 * @class GatewaysApi
 * @extends {BaseAPI}
 */
export class GatewaysApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public deleteGateway(gatewayId: string, options?: RawAxiosRequestConfig) {
    return GatewaysApiFp(this.configuration)
      .deleteGateway(gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public getGateway(gatewayId: string, options?: RawAxiosRequestConfig) {
    return GatewaysApiFp(this.configuration)
      .getGateway(gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {string} [search] Description/id text partial match case insensitive search
   * @param {Array<string>} [tags]
   * @param {string} [setupId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public getGateways(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    search?: string,
    tags?: Array<string>,
    setupId?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysApiFp(this.configuration)
      .getGateways(
        skip,
        take,
        includeTotalCount,
        search,
        tags,
        setupId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public patchGateway(
    gatewayId: string,
    gatewayUpdateInputDto: GatewayUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysApiFp(this.configuration)
      .patchGateway(gatewayId, gatewayUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {GatewayUpdateInputDto} gatewayUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public putGateway(
    gatewayId: string,
    gatewayUpdateInputDto: GatewayUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysApiFp(this.configuration)
      .putGateway(gatewayId, gatewayUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {SwitchEnvCommandRequestPayloadDto} switchEnvCommandRequestPayloadDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysApi
   */
  public validateGatewayCredentials(
    gatewayId: string,
    switchEnvCommandRequestPayloadDto: SwitchEnvCommandRequestPayloadDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysApiFp(this.configuration)
      .validateGatewayCredentials(
        gatewayId,
        switchEnvCommandRequestPayloadDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GatewaysSIMCardActivationApi - axios parameter creator
 * @export
 */
export const GatewaysSIMCardActivationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {ActivateSimInputDto} activateSimInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateSim: async (
      gatewayId: string,
      activateSimInputDto: ActivateSimInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('activateSim', 'gatewayId', gatewayId);
      // verify required parameter 'activateSimInputDto' is not null or undefined
      assertParamExists(
        'activateSim',
        'activateSimInputDto',
        activateSimInputDto,
      );
      const localVarPath = `/api/gateways/{gatewayId}/sim`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        activateSimInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSim: async (
      gatewayId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('getSim', 'gatewayId', gatewayId);
      const localVarPath = `/api/gateways/{gatewayId}/sim`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ProvisioningApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GatewaysSIMCardActivationApi - functional programming interface
 * @export
 */
export const GatewaysSIMCardActivationApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    GatewaysSIMCardActivationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {ActivateSimInputDto} activateSimInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateSim(
      gatewayId: string,
      activateSimInputDto: ActivateSimInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimCardDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateSim(
        gatewayId,
        activateSimInputDto,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysSIMCardActivationApi.activateSim']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSim(
      gatewayId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimCardDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSim(
        gatewayId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysSIMCardActivationApi.getSim']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * GatewaysSIMCardActivationApi - factory interface
 * @export
 */
export const GatewaysSIMCardActivationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GatewaysSIMCardActivationApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {ActivateSimInputDto} activateSimInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateSim(
      gatewayId: string,
      activateSimInputDto: ActivateSimInputDto,
      options?: any,
    ): AxiosPromise<SimCardDto> {
      return localVarFp
        .activateSim(gatewayId, activateSimInputDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSim(gatewayId: string, options?: any): AxiosPromise<SimCardDto> {
      return localVarFp
        .getSim(gatewayId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GatewaysSIMCardActivationApi - object-oriented interface
 * @export
 * @class GatewaysSIMCardActivationApi
 * @extends {BaseAPI}
 */
export class GatewaysSIMCardActivationApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {ActivateSimInputDto} activateSimInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysSIMCardActivationApi
   */
  public activateSim(
    gatewayId: string,
    activateSimInputDto: ActivateSimInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysSIMCardActivationApiFp(this.configuration)
      .activateSim(gatewayId, activateSimInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysSIMCardActivationApi
   */
  public getSim(gatewayId: string, options?: RawAxiosRequestConfig) {
    return GatewaysSIMCardActivationApiFp(this.configuration)
      .getSim(gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GatewaysStateApi - axios parameter creator
 * @export
 */
export const GatewaysStateApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getState: async (
      gatewayId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('getState', 'gatewayId', gatewayId);
      const localVarPath = `/api/gateways/{gatewayId}/state`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayDesiredStateUpdateInputDto} gatewayDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesiredState: async (
      gatewayId: string,
      gatewayDesiredStateUpdateInputDto: GatewayDesiredStateUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewayId' is not null or undefined
      assertParamExists('updateDesiredState', 'gatewayId', gatewayId);
      // verify required parameter 'gatewayDesiredStateUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateDesiredState',
        'gatewayDesiredStateUpdateInputDto',
        gatewayDesiredStateUpdateInputDto,
      );
      const localVarPath = `/api/gateways/{gatewayId}/state/desired`.replace(
        `{${'gatewayId'}}`,
        encodeURIComponent(String(gatewayId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        gatewayDesiredStateUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {GatewaysDesiredStateUpdateInputDto} gatewaysDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesiredStates: async (
      gatewaysDesiredStateUpdateInputDto: GatewaysDesiredStateUpdateInputDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gatewaysDesiredStateUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateDesiredStates',
        'gatewaysDesiredStateUpdateInputDto',
        gatewaysDesiredStateUpdateInputDto,
      );
      const localVarPath = `/api/gateways/states/desired`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        gatewaysDesiredStateUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GatewaysStateApi - functional programming interface
 * @export
 */
export const GatewaysStateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GatewaysStateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getState(
      gatewayId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GatewayStateDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getState(
        gatewayId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysStateApi.getState']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayDesiredStateUpdateInputDto} gatewayDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDesiredState(
      gatewayId: string,
      gatewayDesiredStateUpdateInputDto: GatewayDesiredStateUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GatewayStateDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDesiredState(
          gatewayId,
          gatewayDesiredStateUpdateInputDto,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysStateApi.updateDesiredState']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {GatewaysDesiredStateUpdateInputDto} gatewaysDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDesiredStates(
      gatewaysDesiredStateUpdateInputDto: GatewaysDesiredStateUpdateInputDto,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GatewaysStateUpdateResultDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDesiredStates(
          gatewaysDesiredStateUpdateInputDto,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['GatewaysStateApi.updateDesiredStates']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * GatewaysStateApi - factory interface
 * @export
 */
export const GatewaysStateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GatewaysStateApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getState(gatewayId: string, options?: any): AxiosPromise<GatewayStateDto> {
      return localVarFp
        .getState(gatewayId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
     * @param {GatewayDesiredStateUpdateInputDto} gatewayDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesiredState(
      gatewayId: string,
      gatewayDesiredStateUpdateInputDto: GatewayDesiredStateUpdateInputDto,
      options?: any,
    ): AxiosPromise<GatewayStateDto> {
      return localVarFp
        .updateDesiredState(
          gatewayId,
          gatewayDesiredStateUpdateInputDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {GatewaysDesiredStateUpdateInputDto} gatewaysDesiredStateUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesiredStates(
      gatewaysDesiredStateUpdateInputDto: GatewaysDesiredStateUpdateInputDto,
      options?: any,
    ): AxiosPromise<GatewaysStateUpdateResultDto> {
      return localVarFp
        .updateDesiredStates(gatewaysDesiredStateUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GatewaysStateApi - object-oriented interface
 * @export
 * @class GatewaysStateApi
 * @extends {BaseAPI}
 */
export class GatewaysStateApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysStateApi
   */
  public getState(gatewayId: string, options?: RawAxiosRequestConfig) {
    return GatewaysStateApiFp(this.configuration)
      .getState(gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} gatewayId Well-formatted id regarding type (vendor) of the gateway
   * @param {GatewayDesiredStateUpdateInputDto} gatewayDesiredStateUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysStateApi
   */
  public updateDesiredState(
    gatewayId: string,
    gatewayDesiredStateUpdateInputDto: GatewayDesiredStateUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysStateApiFp(this.configuration)
      .updateDesiredState(gatewayId, gatewayDesiredStateUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {GatewaysDesiredStateUpdateInputDto} gatewaysDesiredStateUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GatewaysStateApi
   */
  public updateDesiredStates(
    gatewaysDesiredStateUpdateInputDto: GatewaysDesiredStateUpdateInputDto,
    options?: RawAxiosRequestConfig,
  ) {
    return GatewaysStateApiFp(this.configuration)
      .updateDesiredStates(gatewaysDesiredStateUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHealth(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetHealth200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHealth(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['HealthApi.getHealth']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealth(options?: any): AxiosPromise<GetHealth200Response> {
      return localVarFp
        .getHealth(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public getHealth(options?: RawAxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .getHealth(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RecordingsApi - axios parameter creator
 * @export
 */
export const RecordingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecording: async (
      recordingId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists('deleteRecording', 'recordingId', recordingId);
      const localVarPath = `/api/recordings/{recordingId}`.replace(
        `{${'recordingId'}}`,
        encodeURIComponent(String(recordingId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecording: async (
      recordingId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists('getRecording', 'recordingId', recordingId);
      const localVarPath = `/api/recordings/{recordingId}`.replace(
        `{${'recordingId'}}`,
        encodeURIComponent(String(recordingId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [gatewayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingsPage: async (
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      gatewayId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/recordings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ApiAccessToken required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (includeTotalCount !== undefined) {
        localVarQueryParameter['includeTotalCount'] = includeTotalCount;
      }

      if (gatewayId !== undefined) {
        localVarQueryParameter['gatewayId'] = gatewayId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {string} xDevId
     * @param {File} file
     * @param {number} [actualDurationSec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRecording: async (
      recordingId: number,
      xDevId: string,
      file: File,
      actualDurationSec?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists('uploadRecording', 'recordingId', recordingId);
      // verify required parameter 'xDevId' is not null or undefined
      assertParamExists('uploadRecording', 'xDevId', xDevId);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadRecording', 'file', file);
      const localVarPath = `/api/d2c/recordings/{recordingId}/data`.replace(
        `{${'recordingId'}}`,
        encodeURIComponent(String(recordingId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication x-dev-pwd required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'x-dev-pwd',
        configuration,
      );

      if (xDevId != null) {
        localVarHeaderParameter['x-dev-id'] = String(xDevId);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (actualDurationSec !== undefined) {
        localVarFormParams.append(
          'actualDurationSec',
          actualDurationSec as any,
        );
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecordingsApi - functional programming interface
 * @export
 */
export const RecordingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RecordingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRecording(
      recordingId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecording(
        recordingId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['RecordingsApi.deleteRecording']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecording(
      recordingId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordingDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecording(
        recordingId,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['RecordingsApi.getRecording']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [gatewayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecordingsPage(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      gatewayId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RecordingsPageDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecordingsPage(
          skip,
          take,
          includeTotalCount,
          gatewayId,
          options,
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['RecordingsApi.getRecordingsPage']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {string} xDevId
     * @param {File} file
     * @param {number} [actualDurationSec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadRecording(
      recordingId: number,
      xDevId: string,
      file: File,
      actualDurationSec?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRecording(
        recordingId,
        xDevId,
        file,
        actualDurationSec,
        options,
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['RecordingsApi.uploadRecording']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * RecordingsApi - factory interface
 * @export
 */
export const RecordingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RecordingsApiFp(configuration);
  return {
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRecording(recordingId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteRecording(recordingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecording(
      recordingId: number,
      options?: any,
    ): AxiosPromise<RecordingDto> {
      return localVarFp
        .getRecording(recordingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} [skip]
     * @param {number} [take]
     * @param {boolean} [includeTotalCount]
     * @param {string} [gatewayId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingsPage(
      skip?: number,
      take?: number,
      includeTotalCount?: boolean,
      gatewayId?: string,
      options?: any,
    ): AxiosPromise<RecordingsPageDto> {
      return localVarFp
        .getRecordingsPage(skip, take, includeTotalCount, gatewayId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {number} recordingId
     * @param {string} xDevId
     * @param {File} file
     * @param {number} [actualDurationSec]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRecording(
      recordingId: number,
      xDevId: string,
      file: File,
      actualDurationSec?: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .uploadRecording(recordingId, xDevId, file, actualDurationSec, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecordingsApi - object-oriented interface
 * @export
 * @class RecordingsApi
 * @extends {BaseAPI}
 */
export class RecordingsApi extends BaseAPI {
  /**
   *
   * @summary
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingsApi
   */
  public deleteRecording(recordingId: number, options?: RawAxiosRequestConfig) {
    return RecordingsApiFp(this.configuration)
      .deleteRecording(recordingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingsApi
   */
  public getRecording(recordingId: number, options?: RawAxiosRequestConfig) {
    return RecordingsApiFp(this.configuration)
      .getRecording(recordingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} [skip]
   * @param {number} [take]
   * @param {boolean} [includeTotalCount]
   * @param {string} [gatewayId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingsApi
   */
  public getRecordingsPage(
    skip?: number,
    take?: number,
    includeTotalCount?: boolean,
    gatewayId?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return RecordingsApiFp(this.configuration)
      .getRecordingsPage(skip, take, includeTotalCount, gatewayId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {number} recordingId
   * @param {string} xDevId
   * @param {File} file
   * @param {number} [actualDurationSec]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingsApi
   */
  public uploadRecording(
    recordingId: number,
    xDevId: string,
    file: File,
    actualDurationSec?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return RecordingsApiFp(this.configuration)
      .uploadRecording(recordingId, xDevId, file, actualDurationSec, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

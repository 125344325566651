import { EventsPageDtoItemsInner } from '../../model/hc-api/api';
import { EventListItem } from './EventListItem';

export interface EventsListProps {
  readonly events: EventsPageDtoItemsInner[];
}

export function EventsList({ events }: EventsListProps) {
  return (
    <ul>
      {events.map((event) => (
        <EventListItem key={event.id} event={event} />
      ))}
    </ul>
  );
}

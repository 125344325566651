import { useMqttSubscription } from '../mqtt/UseMqttSubscription';
import { GatewayStateDto } from '../hc-api/api';
import { useGatewayStateStore } from './GatewayStateStore';

export function useGatewayStateSubscription(gatewayId: string) {
  const updateState = useGatewayStateStore((store) => store.update);

  useMqttSubscription(`hc/c2c/gateways/${gatewayId}/state`, (_, payload) => {
    try {
      const state = JSON.parse(payload.toString()) as GatewayStateDto;
      console.log('new state received', state);
      updateState(state);
    } catch (e: unknown) {
      console.error('failed to parse state', e);
    }
  });
}

import { useGatewaysStore } from '../../model/gateways/GatewaysStore';
import { useEffect, useState } from 'react';
import { GatewayListItem } from './GatewayListItem';
import { SearchInput } from './SearchInput';
import { useNavigate } from 'react-router-dom';
import { GatewayDto } from '../../model/hc-api/api';
import { useGatewayStore } from '../../model/gateways/GatewayStore';

export function Gateways() {
  const gatewaysStore = useGatewaysStore();
  const storeGateway = useGatewayStore((store) => store.setGateway);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const scrollListener = () => {
    setIsScrolledToEnd(
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight,
    );
  };

  useEffect(() => {
    gatewaysStore.refresh();
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    if (isScrolledToEnd && !gatewaysStore.isLoading && gatewaysStore.hasMore) {
      gatewaysStore.loadMore();
    }
  }, [isScrolledToEnd, gatewaysStore.isLoading, gatewaysStore.hasMore]);

  const navigate = useNavigate();
  const onGatewayClick = (gateway: GatewayDto) => {
    storeGateway(gateway);
    navigate(`/gateways/${gateway.id}`);
  };

  return (
    <div className="m-5 flex flex-col gap-2 rounded-xl border-2 p-2">
      <div className="font-bold">Gateways</div>
      <SearchInput />
      <ul>
        {gatewaysStore.gateways.map((gateway) => (
          <GatewayListItem
            key={gateway.id}
            gateway={gateway}
            onClick={onGatewayClick}
          />
        ))}
      </ul>
      {gatewaysStore.isLoading && (
        <div className="text-gray-900">Loading...</div>
      )}
    </div>
  );
}

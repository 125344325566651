import { useGatewayId } from './UseGatewayId';
import { useGatewayStore } from '../../model/gateways/GatewayStore';
import { GatewayDescription } from './GatewayDescription';
import { GatewayStatusIndicator } from './GatewayStatusIndicator';
import { GatewayState } from './state/GatewayState';
import { Events } from './Events';
import { useGatewayStateStore } from '../../model/gateways/GatewayStateStore';
import { useEventsStore } from '../../model/events/UseEventsStore';
import { TouwiButton } from '../common/button/TouwiButton';
import { RefreshBatteriesButton } from './RefreshBatteriesButton';
import { RefreshNetworkInfoButton } from './RefreshNetworkInfoButton';
import { useFwImagesStore } from '../../model/gateways/FwImagesStore';
import { useEffect } from 'react';
import { GetGatewayDebugStateButton } from './GetGatewayDebugStateButton';

export function GatewayScreen() {
  const gatewayId = useGatewayId();
  const gatewayStore = useGatewayStore();
  const gatewayStateStore = useGatewayStateStore();
  const eventsStore = useEventsStore();
  const refreshFwImages = useFwImagesStore((store) => store.refresh);

  useEffect(() => {
    refreshFwImages();
  }, []);

  if (!gatewayId) {
    return <div className="text-red-800">Gateway id not defined</div>;
  }

  const onRefreshClick = () => {
    gatewayStore.refresh();
    gatewayStateStore.refresh();
  };

  return (
    <div className="m-5 flex flex-col gap-2 divide-y rounded-xl border-2 p-2">
      <div className="flex flex-wrap items-center gap-2 p-2">
        <GatewayStatusIndicator
          isOnline={gatewayStore.gateway?.isOnline ?? false}
        />
        <div className="overflow-hidden text-ellipsis font-bold">
          {gatewayId}
        </div>
        <TouwiButton
          onClick={onRefreshClick}
          disabled={
            gatewayStore.isLoading ||
            gatewayStateStore.isLoading ||
            eventsStore.isLoading
          }
          text="Refresh"
        />
      </div>
      {gatewayStore.isLoading && (
        <div className="text-gray-800">Loading...</div>
      )}
      <div className="flex flex-wrap gap-2 p-2">
        <RefreshBatteriesButton gatewayId={gatewayId} />
        <RefreshNetworkInfoButton gatewayId={gatewayId} />
        <GetGatewayDebugStateButton gatewayId={gatewayId} />
      </div>
      {!gatewayStore.isLoading && gatewayStore.gateway && (
        <GatewayDescription />
      )}
      <GatewayState gatewayId={gatewayId} />
      <Events gatewayId={gatewayId} />
    </div>
  );
}

import { GatewayDto } from '../../model/hc-api/api';
import { GatewayStatusIndicator } from './GatewayStatusIndicator';

export interface GatewayListItemProps {
  gateway: GatewayDto;
  onClick?: (gateway: GatewayDto) => void;
}

export function GatewayListItem(props: GatewayListItemProps) {
  return (
    <li
      className="flex cursor-pointer gap-2 rounded-xl p-3 hover:bg-blue-800/10"
      onClick={() => props.onClick?.(props.gateway)}
    >
      <GatewayStatusIndicator isOnline={props.gateway.isOnline} />
      <div className="flex flex-col overflow-hidden text-wrap">
        <div className="font-bold">{props.gateway.id}</div>
        <div>{props.gateway.description || '--'}</div>
      </div>
    </li>
  );
}

import { EventsPageDtoItemsInner } from '../../model/hc-api/api';
import { useState } from 'react';

export interface EventListItemProps {
  readonly event: EventsPageDtoItemsInner;
}

export function EventListItem({ event }: EventListItemProps) {
  const [expanded, setExpanded] = useState(false);

  const onClick = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <li className="my-1 flex flex-wrap justify-between gap-2 rounded-xl bg-gray-100 p-3 hover:bg-blue-800/10">
      {expanded && (
        <pre className="overflow-hidden whitespace-pre-wrap">
          {JSON.stringify(event, null, 2)}
        </pre>
      )}
      {!expanded && (
        <div className="overflow-hidden">
          {humanReadable(event.type.toString())} at {event.triggeredAt} (id:{' '}
          {event.id})
        </div>
      )}
      <div className="hover:cursor-pointer" onClick={onClick}>
        {expanded ? 'Less...' : 'More...'}
      </div>
    </li>
  );
}

function humanReadable(s: string): string {
  s = s.replaceAll('_', ' ').trim();
  return s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase();
}

import { TouwiButton } from '../common/button/TouwiButton';
import { useRefreshBatteriesState } from '../../model/gateways/UseRefreshBatteriesState';

export interface RefreshBatteriesButtonProps {
  gatewayId: string;
}

export function RefreshBatteriesButton(props: RefreshBatteriesButtonProps) {
  const [isRunning, refresh] = useRefreshBatteriesState();

  return (
    <TouwiButton
      onClick={() => refresh(props.gatewayId)}
      disabled={isRunning}
      text="Refresh batteries"
    />
  );
}

import { TouwiButton } from '../common/button/TouwiButton';
import { useRefreshNetworkInfo } from '../../model/gateways/UseRefreshNetworkInfo';

export interface RefreshNetworkInfoProps {
  gatewayId: string;
}

export function RefreshNetworkInfoButton(props: RefreshNetworkInfoProps) {
  const [isRunning, refresh] = useRefreshNetworkInfo();

  return (
    <TouwiButton
      onClick={() => refresh(props.gatewayId)}
      disabled={isRunning}
      text="Refresh network info"
    />
  );
}

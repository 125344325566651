import { FwImageDto } from '../hc-api/api';
import { create } from 'zustand';
import { useErrorsStore } from '../errors/ErrorsStore';
import { isAxiosError } from 'axios';
import { fwImagesApi } from '../hc-api';

export interface FwImagesStore {
  gwImages: FwImageDto[];
  sensorImages: FwImageDto[];
  isLoading: boolean;

  refresh: () => void;
}

export const useFwImagesStore = create<FwImagesStore>()((set, get) => ({
  gwImages: [],
  sensorImages: [],
  isLoading: false,

  refresh: async () => {
    const isLoading = get().isLoading;
    if (isLoading) {
      return;
    }

    set({ isLoading: true });

    try {
      const allImages: FwImageDto[] = [];
      let allFetched = false;
      let skip = 0;
      const take = 1000;
      while (!allFetched) {
        const page = await fwImagesApi.getFwImages(skip, take);
        allImages.push(...page.data.items);

        allFetched = page.data.items.length < take;
        skip += take;
      }
      const gwImages: FwImageDto[] = [];
      const sensorImages: FwImageDto[] = [];
      allImages.forEach((image) => {
        if (image.type === 'gateway') {
          gwImages.push(image);
        } else {
          sensorImages.push(image);
        }
      });

      gwImages.sort((a, b) => -compareVersions(a.version, b.version));
      sensorImages.sort((a, b) => -compareVersions(a.version, b.version));
      set({
        gwImages: gwImages,
        sensorImages: sensorImages,
      });
    } catch (e: unknown) {
      console.log('Failed to refresh FW images', e);
      useErrorsStore
        .getState()
        .add(
          `Failed to refresh FW images: ${
            isAxiosError(e) ? e.response?.data?.message ?? e : e
          }`,
        );
    } finally {
      set({ isLoading: false });
    }
  },
}));

function compareVersions(a: string, b: string): number {
  const aTokens = a.split('.');
  const bTokens = b.split('.');
  const n = Math.max(aTokens.length, bTokens.length);
  for (let i = 0; i < n; i++) {
    const ai = aTokens[i] ?? '';
    const bi = bTokens[i] ?? '';
    let diff = 0;
    if (!Number.isNaN(ai) && !Number.isNaN(bi)) {
      diff = +ai - +bi;
    } else {
      diff = ai.localeCompare(bi);
    }
    if (diff !== 0) {
      return diff;
    }
  }

  return 0;
}

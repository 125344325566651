import { Modal } from '../common/modal/Modal';
import { useGetGatewayState } from '../../model/gateways/UseGetGatewayState';
import {
  AccelerometerSampleValueDto,
  PressureSampleValueDto,
} from '../../model/hc-api/api';

export interface GetGatewayStateModalStateProps {
  gatewayId: string;
  onCancel: () => void;
}

export function GetGatewayDebugStateModal({
  gatewayId,
  onCancel,
}: GetGatewayStateModalStateProps) {
  const gatewayState = useGetGatewayState(gatewayId);

  return (
    <Modal title={`${gatewayId} debug state`} onCancel={onCancel}>
      <div className="p-5">
        {gatewayState.isRunning && (
          <div className="h-1 text-center">Loading...</div>
        )}
        {gatewayState.error && (
          <div className="h-1 text-center text-red-800">
            {gatewayState.error.message}
          </div>
        )}
        {gatewayState.result && (
          <div className="flex flex-col gap-8 overflow-y-auto">
            <LabelledValue
              label="Mode:"
              value={gatewayState.result.mode ?? '<NONE>'}
            />
            <LabelledValue
              label="Time since boot:"
              value={`${gatewayState.result.timeSinceBootMillis} ms`}
            />
            <LabelledValue
              label="Gateway time:"
              value={gatewayState.result.gatewayTime}
            />
            {gatewayState.result.lastDoorEventTime && (
              <LabelledValue
                label="Last door event time:"
                value={gatewayState.result.lastDoorEventTime}
              />
            )}
            {gatewayState.result.lastRideEventTime && (
              <LabelledValue
                label="Last ride event time:"
                value={gatewayState.result.lastRideEventTime}
              />
            )}
            {gatewayState.result.nProcessedAccelSamples && (
              <LabelledValue
                label="Processed accel samples:"
                value={gatewayState.result.nProcessedAccelSamples.toString()}
              />
            )}
            {gatewayState.result.nProcessedPressureSamples && (
              <LabelledValue
                label="Processed pressure samples:"
                value={gatewayState.result.nProcessedPressureSamples.toString()}
              />
            )}
            {gatewayState.result.door1SensorLastConnectedAt && (
              <LabelledValue
                label="Door 1 sensor last connected at:"
                value={gatewayState.result.door1SensorLastConnectedAt}
              />
            )}
            {gatewayState.result.door2SensorLastConnectedAt && (
              <LabelledValue
                label="Door 2 sensor last connected at:"
                value={gatewayState.result.door2SensorLastConnectedAt}
              />
            )}
            {gatewayState.result.refSensorLastConnectedAt && (
              <LabelledValue
                label="Ref sensor last connected at:"
                value={gatewayState.result.refSensorLastConnectedAt}
              />
            )}
            {gatewayState.result.lastAccelSampleValue && (
              <LabelledValue
                label="Last accel sample value:"
                value={formatAccelValue(
                  gatewayState.result.lastAccelSampleValue,
                )}
              />
            )}
            {gatewayState.result.lastPressureSampleValue && (
              <LabelledValue
                label="Last pressure sample value:"
                value={formatPressureValue(
                  gatewayState.result.lastPressureSampleValue,
                )}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

interface KeyValueItemProps {
  label: string;
  value: string;
}

function LabelledValue({ label, value }: KeyValueItemProps) {
  return (
    <div className="flex flex-col gap-3 ">
      <div className="h-2 font-bold">{label}</div>
      <div className="h-1">{value}</div>
    </div>
  );
}

function formatAccelValue(value: AccelerometerSampleValueDto): string {
  return `(${value.x.toFixed(3)}, ${value.y.toFixed(3)}, ${value.z.toFixed(
    3,
  )})`;
}

function formatPressureValue(value: PressureSampleValueDto): string {
  return `P: ${value.pressurePascals.toFixed(
    2,
  )}; T: ${value.temperatureCelsiusDegrees.toFixed(2)}`;
}

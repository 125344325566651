import React, { useState } from 'react';
import { TouwiButton } from '../common/button/TouwiButton';
import { useGatewayStore } from '../../model/gateways/GatewayStore';
import { TouwiInput } from '../common/input/TouwiInput';

export function GatewayDescription() {
  const gatewayStore = useGatewayStore();

  const [description, setDescription] = useState(
    gatewayStore.gateway?.description ?? '',
  );

  const [setupId, setSetupId] = useState(gatewayStore.gateway?.setupId ?? '');

  const descriptionHasChanges =
    description !== (gatewayStore.gateway?.description ?? '');

  const setupIdHasChanges = setupId !== (gatewayStore.gateway?.setupId ?? '');

  const disableInput = gatewayStore.isLoading;

  const disableSave =
    (!descriptionHasChanges && !setupIdHasChanges) ||
    gatewayStore.isSaving ||
    gatewayStore.isLoading;

  const onSaveClick = () => {
    gatewayStore.updateGateway({
      description: description || null,
      setupId: setupId || null,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <TouwiInput
        inputId="gw-description-input"
        label={descriptionHasChanges ? 'Description *' : 'Description'}
        value={description}
        onValue={setDescription}
        disabled={disableInput}
      />

      <TouwiInput
        inputId="gw-setup-id-input"
        label={setupIdHasChanges ? 'Setup ID *' : 'Setup ID'}
        value={setupId}
        onValue={setSetupId}
        disabled={disableInput}
      />
      <TouwiButton text="Save" disabled={disableSave} onClick={onSaveClick} />
    </div>
  );
}

import { MqttMessageCallback } from './mqtt-message-callback';
import { UnsubscribeFunction } from './mqtt.service';
import { isSubscriptionMatchTopic } from './topic-matcher';

export interface Subscription {
  readonly topic: string;
  readonly callback: MqttMessageCallback;
}

export class TopicDispatcher {
  private subscriptions: Subscription[] = [];

  subscribe(topic: string, callback: MqttMessageCallback): UnsubscribeFunction {
    this.subscriptions = [
      ...this.subscriptions,
      {
        topic: topic,
        callback: callback,
      },
    ];

    return () => {
      const idx = this.subscriptions.findIndex(
        (s) => s.topic === topic && s.callback === callback,
      );

      if (idx !== -1) {
        this.subscriptions = [
          ...this.subscriptions.slice(0, idx),
          ...this.subscriptions.slice(idx + 1),
        ];
      }
    };
  }

  dispatch(topic: string, message: Buffer) {
    this.subscriptions.forEach((subscription) => {
      if (isSubscriptionMatchTopic(subscription.topic, topic)) {
        subscription.callback(topic, message);
      }
    });
  }
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginBlock } from './LoginBlock';

export function LoginScreen() {
  return (
    <div className="flex h-screen items-center justify-center">
      <Routes>
        <Route path="/" element={<LoginBlock />}>
          <Route path="*" element={<LoginBlock />} />
        </Route>
      </Routes>
    </div>
  );
}

import { MqttMessageCallback } from './mqtt-message-callback';
import { useMqtt } from './UseMqtt';
import { useEffect } from 'react';

export function useMqttSubscription(
  topic: string,
  callback: MqttMessageCallback,
) {
  const mqtt = useMqtt();

  useEffect(() => {
    if (!mqtt) {
      return;
    }

    const unsubscribePromise = mqtt
      .subscribe(
        {
          qos: 1,
          topic: topic,
        },
        callback,
      )
      .then((unsubscribe) => {
        console.log(`subscribed to mqtt ${topic}`);
        return unsubscribe;
      })
      .catch((e) => {
        console.error(`failed to subscribe to mqtt ${topic}`, e);
        return null;
      });

    return () => {
      unsubscribePromise.then((unsubscribe) => {
        unsubscribe?.();
        console.log(`unsubscribed from mqtt ${topic}`);
      });
    };
  }, [mqtt, topic]);
}

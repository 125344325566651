import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../model/common/UseDebounce';
import { useGatewaysStore } from '../../model/gateways/GatewaysStore';

export function SearchInput() {
  const key = 'search';

  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState(searchParams.get(key) ?? '');
  const debouncedInputValue = useDebounce(inputValue);

  const setSearch = useGatewaysStore((store) => store.setSearch);

  useEffect(() => {
    if (debouncedInputValue) {
      searchParams.set(key, debouncedInputValue);
      setSearch(debouncedInputValue);
    } else {
      searchParams.delete(key);
      setSearch('');
    }
    setSearchParams(searchParams);
  }, [debouncedInputValue]);

  return (
    <input
      type="text"
      placeholder="Search gateways"
      className="rounded-xl border-[1px] p-2"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
}

import { useParams } from 'react-router-dom';
import { useGatewayStore } from '../../model/gateways/GatewayStore';
import { useEffect } from 'react';

export function useGatewayId(): string | undefined {
  const { gatewayId } = useParams();
  const gatewayStore = useGatewayStore((store) => ({
    setGatewayId: store.setGatewayId,
    reset: store.reset,
  }));

  useEffect(() => {
    gatewayStore.setGatewayId(gatewayId ?? null);
    return () => {
      gatewayStore.reset();
    };
  }, [gatewayId]);

  return gatewayId;
}

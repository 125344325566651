import { NavLink } from 'react-router-dom';
import { MouseEventHandler, ReactNode } from 'react';
import { TouwiButtonStyle } from './TouwiButtonStyle';

export interface ActionLinkProps {
  readonly style?: TouwiButtonStyle | undefined;
  readonly to: string;
  readonly children: ReactNode;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
}

export function ActionLink(props: ActionLinkProps) {
  const styleClass = {
    normal: 'text-black-800',
    dangerous: 'text-red-800',
  };

  return (
    <NavLink
      className={`font-bold ${styleClass[props.style ?? 'normal']}`}
      to={props.to}
      onClick={props.onClick}
    >
      {props.children}
    </NavLink>
  );
}

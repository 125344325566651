import QrCodeIcon from './qr-code.svg';

export interface QrCodeButtonProps {
  onClick: () => void;
}

export function QrCodeButton({ onClick }: QrCodeButtonProps) {
  return (
    <button onClick={onClick}>
      <img src={QrCodeIcon} alt="QR" />
    </button>
  );
}

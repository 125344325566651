import {
  Configuration,
  EventsApiFactory,
  FWImagesApiFactory,
  GatewayCommandsApiFactory,
  GatewaysApiFactory,
  GatewaysStateApiFactory,
} from './api';
import { useAuthStore } from '../auth/AuthStore';

const HC_API_BASE_PATH =
  process.env.REACT_APP_HC_API_BASE_URL ??
  'https://hc-api-service-sit.liftmanager05.io';

function newConfiguration(token: string | (() => string)): Configuration {
  return new Configuration({
    basePath: HC_API_BASE_PATH,
    accessToken: token,
  });
}

export async function tryToken(token: string) {
  const api = GatewaysApiFactory(newConfiguration(token));
  await api.getGateways(0, 1, false);
}

const gatewaysApiConfig = newConfiguration(
  () => useAuthStore.getState().token ?? '',
);

export const gatewaysApi = GatewaysApiFactory(gatewaysApiConfig);

const gatewaysStateApiConfig = newConfiguration(
  () => useAuthStore.getState().token ?? '',
);

export const gatewaysStateApi = GatewaysStateApiFactory(gatewaysStateApiConfig);

const eventsApiConfig = newConfiguration(
  () => useAuthStore.getState().token ?? '',
);

export const eventsApi = EventsApiFactory(eventsApiConfig);

const gwCommandsApiConfig = newConfiguration(
  () => useAuthStore.getState().token ?? '',
);

export const gwCommandsApi = GatewayCommandsApiFactory(gwCommandsApiConfig);

const fwImagesApiConfig = newConfiguration(
  () => useAuthStore.getState().token ?? '',
);

export const fwImagesApi = FWImagesApiFactory(fwImagesApiConfig);

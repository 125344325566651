import { GatewayDto } from '../hc-api/api';
import { create } from 'zustand';
import { useErrorsStore } from '../errors/ErrorsStore';
import { gatewaysApi } from '../hc-api';

const PAGE_SIZE = 100;

export interface GatewaysStore {
  readonly gateways: GatewayDto[];
  readonly isLoading: boolean;
  readonly hasMore: boolean;

  readonly search: string;

  readonly setSearch: (value: string) => void;

  readonly refresh: () => void;

  readonly loadMore: () => void;
}

export const useGatewaysStore = create<GatewaysStore>()((set, get) => ({
  gateways: [],
  isLoading: false,
  hasMore: true,
  search: '',

  setSearch: async (value: string) => {
    set({ search: value });
    get().refresh();
  },

  refresh: async () => {
    const isLoading = get().isLoading;
    if (isLoading) {
      return;
    }

    set({ gateways: [] });
    get().loadMore();
  },

  loadMore: async () => {
    const isLoading = get().isLoading;
    if (isLoading) {
      return;
    }

    set({ isLoading: true });
    try {
      let done = false;
      while (!done) {
        const skip = get().gateways.length;
        const search = get().search;
        const response = await gatewaysApi.getGateways(
          skip,
          PAGE_SIZE,
          false,
          search || undefined,
        );
        if (search === get().search) {
          done = true;
          const hasMore = response.data.items.length === PAGE_SIZE;
          const currentGateways = get().gateways;
          set({
            gateways: [...currentGateways, ...response.data.items],
            hasMore: hasMore,
          });
        }
      }
    } catch (e: unknown) {
      console.log('failed to load gateways', e);
      useErrorsStore.getState().add(`Failed to load gateways: ${e}`);
    } finally {
      set({ isLoading: false });
    }
  },
}));

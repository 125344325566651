export const isSubscriptionMatchTopic = (
  subscription: string,
  topic: string,
): boolean => {
  let subscriptionPath = subscription.split('/');
  if (subscriptionPath[0] === '$share') {
    subscriptionPath = subscriptionPath.slice(2);
  }

  const topicPath = topic.split('/');
  const pathLength: number = subscriptionPath.length;

  for (let i = 0; i < pathLength; i += 1) {
    const subscriptionPathItem: string = subscriptionPath[i];
    const topicPathItem: string = topicPath[i];

    if (subscriptionPathItem === '#') {
      return topicPath.length >= pathLength - 1;
    }

    if (
      subscriptionPathItem !== topicPathItem &&
      subscriptionPathItem !== '+'
    ) {
      return false;
    }
  }

  return pathLength === topicPath.length;
};

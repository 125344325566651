import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';

export interface TouwiInputProps {
  readonly inputId: string;
  readonly label: string;
  readonly disabled?: boolean | undefined;
  readonly value: string;
  readonly onValue?: (newValue: string) => void | undefined;
  readonly type?: HTMLInputTypeAttribute | undefined;
  readonly error?: string | undefined;
  readonly pattern?: string | undefined;
}

export function TouwiInput(props: TouwiInputProps) {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onValue) {
      props.onValue(event.target.value);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={props.inputId} className="text-sm text-black">
        {props.label}
      </label>

      <input
        id={props.inputId}
        type={props.type}
        className="w-full rounded border border-blue-800 p-1 text-black"
        disabled={props.disabled}
        onChange={onInputChange}
        value={props.value}
        pattern={props.pattern}
      />

      {props.error && (
        <label
          htmlFor={props.inputId}
          className="text-sm font-semibold text-red-800"
        >
          {props.error}
        </label>
      )}
    </div>
  );
}

export const makeClientId = () => {
  const tokens = ['hc-web-'];

  const nRandomDigits = 10;

  for (let i = 0; i < nRandomDigits; ++i) {
    const d = Math.floor(Math.random() * 10);
    tokens.push(d.toString());
  }

  return tokens.join('');
};

import { useEventsStore } from '../../model/events/UseEventsStore';
import { useEffect } from 'react';
import { EventsList } from './EventsList';
import { useEventsMqttSubscription } from '../../model/events/UseEventsMqttSubscription';
import { TouwiButton } from '../common/button/TouwiButton';

export interface GatewayEventsProps {
  gatewayId: string;
}

export function Events(props: GatewayEventsProps) {
  useEventsMqttSubscription(props.gatewayId);

  const eventsStore = useEventsStore();

  useEffect(() => {
    eventsStore.setGatewayId(props.gatewayId);
    return () => {
      eventsStore.reset();
    };
  }, [props.gatewayId]);

  if (eventsStore.events.length === 0) {
    return <div className="py-2">Events will appear here...</div>;
  }

  return (
    <div className="flex flex-col gap-2 py-2">
      <div>Recent events:</div>
      <EventsList events={eventsStore.events} />
      {eventsStore.hasMore && (
        <TouwiButton
          text={'Load more'}
          onClick={() => {
            eventsStore.loadMore();
          }}
          disabled={eventsStore.isLoading}
        />
      )}
    </div>
  );
}

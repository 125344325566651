import React from 'react';

import { ErrorItem } from './ErrorItem';
import { useErrorsStore } from '../../model/errors/ErrorsStore';

export function ErrorsSection() {
  const state = useErrorsStore();
  if (state.errors.length == 0) {
    return <></>;
  }

  return (
    <div className="absolute end-0 top-0 me-[2vw] mt-[2vh] flex w-[30%] flex-col gap-2">
      {state.errors.map((error) => (
        <ErrorItem
          message={error.message}
          key={error.id}
          onDismiss={() => {
            state.remove(error.id);
          }}
        />
      ))}
    </div>
  );
}

import { create } from 'zustand';

const MAX_ERRORS = 5;

export interface Error {
  id: string;
  message: string;
}

export interface ErrorsStore {
  errors: Error[];

  add: (error: string) => void;

  remove: (id: string) => void;

  clear: () => void;
}

export const useErrorsStore = create<ErrorsStore>()((setState, getState) => ({
  errors: [],

  add: (error: string) => {
    setState({
      errors: [
        ...getState().errors,
        {
          id: Array.from(Array(2), () => Math.round(Math.random() * 1000)).join(
            '-',
          ),
          message: error,
        },
      ].slice(-MAX_ERRORS),
    });
  },

  remove: (id: string) => {
    const errors = [...getState().errors];
    const index = errors.findIndex((e) => e.id === id);
    if (index < 0) {
      return;
    }

    errors.splice(index, 1);
    setState({ errors: errors });
  },

  clear: () => {
    setState({
      errors: [],
    });
  },
}));

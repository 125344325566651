import React from 'react';
import { useAuthStore } from './model/auth/AuthStore';
import { Home } from './components/home/Home';
import { BrowserRouter } from 'react-router-dom';
import { LoginScreen } from './components/login/LoginScreen';
import { ErrorsSection } from './components/errors/ErrorsSection';

export function App() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const Content = isAuthenticated ? Home : LoginScreen;

  return (
    <>
      <ErrorsSection />
      <BrowserRouter>
        <Content />
      </BrowserRouter>
    </>
  );
}

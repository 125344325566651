import { useEffect } from 'react';
import { useGatewayStateStore } from '../../../model/gateways/GatewayStateStore';
import { GatewayStateForm } from './GatewayStateForm';
import { useGatewayStateSubscription } from '../../../model/gateways/UseGatewayStateSubscription';

export interface GatewayStateProps {
  readonly gatewayId: string;
}

export function GatewayState({ gatewayId }: GatewayStateProps) {
  const stateStore = useGatewayStateStore();
  useEffect(() => {
    stateStore.setGatewayId(gatewayId);
    return () => {
      stateStore.reset();
    };
  }, [gatewayId]);
  useGatewayStateSubscription(gatewayId);

  return (
    <div className="py-2">
      {stateStore.isLoading && (
        <div className="text-gray-700">Loading gateway state...</div>
      )}
      {!stateStore.isLoading && !stateStore.state && (
        <div className="text-red-800">No state loaded =(</div>
      )}
      {!stateStore.isLoading && stateStore.state && <GatewayStateForm />}
    </div>
  );
}

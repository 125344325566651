import { TouwiButton } from '../common/button/TouwiButton';
import { GetGatewayDebugStateModal } from './GetGatewayDebugStateModal';
import { useState } from 'react';

export interface GetGatewayStateButtonProps {
  gatewayId: string;
}

export function GetGatewayDebugStateButton(props: GetGatewayStateButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <GetGatewayDebugStateModal
          gatewayId={props.gatewayId}
          onCancel={() => setShowModal(false)}
        />
      )}
      <TouwiButton
        onClick={() => setShowModal(true)}
        text="Get gateway debug state"
      />
    </>
  );
}

import { useEffect, useState } from 'react';
import { useErrorsStore } from '../errors/ErrorsStore';
import { gwCommandsApi } from '../hc-api';

export function useRefreshBatteriesState(): [boolean, (_: string) => void] {
  const addError = useErrorsStore((store) => store.add);

  const [isRunning, setIsRunning] = useState(false);
  const [gatewayId, setGatewayId] = useState<string | null>(null);

  useEffect(() => {
    if (!gatewayId) {
      return;
    }

    setIsRunning(true);
    gwCommandsApi
      .refreshSensorsBatteryLevelCommand(gatewayId)
      .then(() => {
        setIsRunning(false);
      })
      .catch((e) => {
        console.error('Failed to refresh gateway state', e);
        addError(
          `Failed to refresh batteries state: ${
            e?.response?.data?.message ?? e
          }`,
        );
      })
      .finally(() => {
        setIsRunning(false);
        setGatewayId(null);
      });
  }, [gatewayId]);

  return [isRunning, setGatewayId];
}

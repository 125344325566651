import React from 'react';

export interface ErrorItemProps {
  readonly message: string;
  readonly onDismiss: () => void;
}

export function ErrorItem(props: ErrorItemProps) {
  return (
    <div className="flex items-center justify-between rounded-[10px] bg-red-600/90 pb-2 pe-4 ps-4 pt-2 text-[1em] text-white hover:bg-red-600">
      <div>Error: {props.message}</div>
      <button
        className="onClick={props.onDismiss} h-17 w-17 rounded-[3px] p-1 text-white"
        onClick={props.onDismiss}
      >
        ✕
      </button>
    </div>
  );
}

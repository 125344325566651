export interface GatewayStatusIndicatorProps {
  isOnline: boolean;
}

export function GatewayStatusIndicator(props: GatewayStatusIndicatorProps) {
  const statusClass = props.isOnline
    ? 'w-2 min-w-2 rounded-xl bg-green-700 self-stretch'
    : 'w-2 min-w-2 rounded-xl bg-red-700 self-stretch';

  return (
    <div
      className={statusClass}
      title={props.isOnline ? 'Online' : 'Offline'}
    />
  );
}

import { ReactNode, useEffect } from 'react';

export interface ModalProps {
  title: string;
  children: ReactNode;
  onCancel: () => void;
}

export function Modal({ title, children, onCancel }: ModalProps) {
  const keyDownListener = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener);
    };
  }, []);
  return (
    <>
      <div
        className="fixed bottom-0 left-0 right-0 top-0 bg-black/60"
        onClick={onCancel}
      ></div>
      <div className="fixed left-1/2 top-1/2 flex w-[95%] -translate-x-1/2 -translate-y-1/2 flex-col gap-2 rounded bg-white p-5 sm:w-[500px]">
        <div className="mb-2 text-center text-lg">{title}</div>
        {children}
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { mqttService, MqttService } from './mqtt.service';

export function useMqtt() {
  const [service, setService] = useState<MqttService | null>(null);

  useEffect(() => {
    mqttService
      .then((service) => {
        setService(service);
      })
      .catch((e) => {
        console.log('[useMqtt] failed', e);
      });
  }, []);

  return service;
}

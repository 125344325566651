import { GatewayAttributeModeValueDtoValueEnum } from '../../../model/hc-api/api';
import { StateAttribute } from './StateAttribute';
import { useState } from 'react';
import { TouwiButton } from '../../common/button/TouwiButton';
import { useGatewayStateStore } from '../../../model/gateways/GatewayStateStore';
import { Modal } from '../../common/modal/Modal';
import { Scanner } from '@yudiel/react-qr-scanner';
import { QrCodeButton } from './QrCodeButton';
import { useFwImagesStore } from '../../../model/gateways/FwImagesStore';

export function GatewayStateForm() {
  const stateStore = useGatewayStateStore();
  const state = stateStore.state;
  if (!state) {
    return <></>;
  }

  const gwImages = useFwImagesStore((store) => store.gwImages);
  const sensorImages = useFwImagesStore((store) => store.sensorImages);

  let stateHasChanges = false;

  const FW_UNSET = '--';
  const [gatewayFw, setGatewayFw] = useState(
    state.desired.gatewayFirmwareVersion?.value ?? FW_UNSET,
  );
  const gwFwHasChanges =
    gatewayFw !== (state.desired.gatewayFirmwareVersion?.value ?? FW_UNSET);
  stateHasChanges = stateHasChanges || gwFwHasChanges;

  const [sensorFw, setSensorFw] = useState(
    state.desired.sensorsFirmwareVersion?.value ?? FW_UNSET,
  );
  const sensorFwHasChanges =
    sensorFw !== (state.desired.sensorsFirmwareVersion?.value ?? FW_UNSET);
  stateHasChanges = stateHasChanges || sensorFwHasChanges;

  const [refSensorId, setRefSensorId] = useState(
    state.desired.referenceSensorId?.value ?? '',
  );
  const refSensorIdHasChanges =
    refSensorId !== (state.desired.referenceSensorId?.value ?? '');
  stateHasChanges = stateHasChanges || refSensorIdHasChanges;

  const [door1SensorId, setDoor1SensorId] = useState(
    state.desired.door1SensorId?.value ?? '',
  );
  const door1SensorIdHasChanges =
    door1SensorId !== (state.desired.door1SensorId?.value ?? '');
  stateHasChanges = stateHasChanges || door1SensorIdHasChanges;

  const [door2SensorId, setDoor2SensorId] = useState(
    state.desired.door2SensorId?.value ?? '',
  );
  const door2SensorIdHasChanges =
    door2SensorId !== (state.desired.door2SensorId?.value ?? '');
  stateHasChanges = stateHasChanges || door2SensorIdHasChanges;

  const MODE_UNSET = '--';
  const [mode, setMode] = useState(state.desired.mode?.value ?? MODE_UNSET);
  const modeHasChanges = mode !== (state.desired.mode?.value ?? MODE_UNSET);
  stateHasChanges = stateHasChanges || modeHasChanges;

  const [eventsBufferingMaxIntervalText, setEventsBufferingMaxIntervalText] =
    useState(
      state.desired.eventsBufferingMaxIntervalSec?.value?.toString() ?? '',
    );
  const eventsBufferingMaxIntervalHasChanges =
    eventsBufferingMaxIntervalText !==
    (state.desired.eventsBufferingMaxIntervalSec?.value?.toString() ?? '');
  stateHasChanges = stateHasChanges || eventsBufferingMaxIntervalHasChanges;

  const eventsBufferingMaxIntervalSec = () => {
    if (eventsBufferingMaxIntervalText.length === 0) {
      return null;
    }

    const n = +eventsBufferingMaxIntervalText;
    if (Number.isNaN(n)) {
      return null;
    }

    return n;
  };

  const noSetter = () => {
    //do nothing
  };
  const [qrConfig, setQrConfig] = useState<{
    opened: boolean;
    label: string;
    setter: (value: string) => void;
  }>({
    opened: false,
    label: '',
    setter: noSetter,
  });

  const resetQrConfig = () => {
    setQrConfig({
      opened: false,
      label: '',
      setter: noSetter,
    });
  };

  const onSaveClick = () => {
    stateStore.save({
      gatewayFirmwareVersion: gatewayFw === FW_UNSET ? null : gatewayFw,
      sensorsFirmwareVersion: sensorFw === FW_UNSET ? null : sensorFw,
      door1SensorId: door1SensorId || null,
      door2SensorId: door2SensorId || null,
      referenceSensorId: refSensorId || null,
      eventsBufferingMaxIntervalSec: eventsBufferingMaxIntervalSec(),
      mode:
        mode === MODE_UNSET
          ? null
          : (mode as GatewayAttributeModeValueDtoValueEnum),
    });
  };

  return (
    <>
      {qrConfig.opened && (
        <Modal
          title={`Scan ${qrConfig.label} QR Code`}
          onCancel={resetQrConfig}
        >
          <Scanner
            components={{
              audio: false,
            }}
            onScan={(result) => {
              console.log(`qr decoded`, result);
              const code = result[0]?.rawValue;
              if (code) {
                qrConfig.setter(code);
                resetQrConfig();
              }
            }}
            onError={(e) => {
              console.log('qr error', e);
            }}
          />
          <button
            className="rounded bg-red-400 p-2 font-bold text-white hover:bg-red-600"
            onClick={resetQrConfig}
          >
            Cancel
          </button>
        </Modal>
      )}
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <StateAttribute
            label={'Gateway FW'}
            editable={true}
            inputId="gateway-fw"
            desiredValue={gatewayFw}
            onUpdateDesiredValue={setGatewayFw}
            isModified={gwFwHasChanges}
            reportedValue={state.reported.gatewayFirmwareVersion?.value}
            disabled={stateStore.isSaving}
            options={[FW_UNSET, ...gwImages.map((image) => image.version)]}
          />

          <StateAttribute
            label={'Sensor FW'}
            editable={true}
            inputId="sensor-fw"
            desiredValue={sensorFw}
            onUpdateDesiredValue={setSensorFw}
            isModified={sensorFwHasChanges}
            reportedValue={`D1: 
            ${state.reported.door1SensorFirmwareVersion?.value ?? '--'}; 
            D2: 
            ${state.reported.door2SensorFirmwareVersion?.value ?? '--'}; 
            R: 
            ${state.reported.referenceSensorFirmwareVersion?.value ?? '--'}`}
            disabled={stateStore.isSaving}
            options={[FW_UNSET, ...sensorImages.map((image) => image.version)]}
          />

          <StateAttribute
            label={'Reference sensor'}
            editable={true}
            inputId="ref-sensor"
            desiredValue={refSensorId}
            onUpdateDesiredValue={setRefSensorId}
            isModified={refSensorIdHasChanges}
            reportedValue={state.reported.referenceSensorId?.value}
            disabled={stateStore.isSaving}
            extraInput={
              <QrCodeButton
                onClick={() => {
                  setQrConfig({
                    opened: true,
                    label: 'reference sensor',
                    setter: setRefSensorId,
                  });
                }}
              />
            }
          />

          <StateAttribute
            label={'Door 1 sensor'}
            editable={true}
            inputId="door-1-sensor"
            desiredValue={door1SensorId}
            onUpdateDesiredValue={setDoor1SensorId}
            isModified={door1SensorIdHasChanges}
            reportedValue={state.reported.door1SensorId?.value}
            disabled={stateStore.isSaving}
            extraInput={
              <QrCodeButton
                onClick={() => {
                  setQrConfig({
                    opened: true,
                    label: 'door-1 sensor',
                    setter: setDoor1SensorId,
                  });
                }}
              />
            }
          />

          <StateAttribute
            label={'Door 2 sensor'}
            editable={true}
            inputId="door-2-sensor"
            desiredValue={door2SensorId}
            onUpdateDesiredValue={setDoor2SensorId}
            isModified={door2SensorIdHasChanges}
            reportedValue={state.reported.door2SensorId?.value}
            disabled={stateStore.isSaving}
            extraInput={
              <QrCodeButton
                onClick={() => {
                  setQrConfig({
                    opened: true,
                    label: 'door-2 sensor',
                    setter: setDoor2SensorId,
                  });
                }}
              />
            }
          />

          <StateAttribute
            label={'Mode'}
            editable={true}
            inputId="mode"
            desiredValue={mode}
            onUpdateDesiredValue={setMode}
            isModified={modeHasChanges}
            reportedValue={state.reported.mode?.value}
            options={[
              MODE_UNSET,
              ...Object.values(GatewayAttributeModeValueDtoValueEnum),
            ]}
            disabled={stateStore.isSaving}
          />

          <StateAttribute
            label={'Max events buffering (sec)'}
            editable={true}
            inputId="max-buffering-interval"
            desiredValue={eventsBufferingMaxIntervalText}
            onUpdateDesiredValue={(newValue) => {
              newValue = newValue.trim();
              if (newValue.length === 0 || /^\d+$/.test(newValue)) {
                setEventsBufferingMaxIntervalText(newValue);
              }
            }}
            isModified={modeHasChanges}
            reportedValue={
              state.reported.eventsBufferingMaxIntervalSec?.value?.toString() ??
              ''
            }
            disabled={stateStore.isSaving}
          />

          <StateAttribute
            label={'Door 1 sensor battery level'}
            editable={false}
            isModified={false}
            inputId="door-1-sensor-battery"
            hideDesired={true}
            reportedValue={batteryLevelString(
              state.reported.door1SensorBatteryLevel?.value,
            )}
          />

          <StateAttribute
            label={'Door 2 sensor battery level'}
            editable={false}
            isModified={false}
            inputId="door-2-sensor-battery"
            hideDesired={true}
            reportedValue={batteryLevelString(
              state.reported.door2SensorBatteryLevel?.value,
            )}
          />

          <StateAttribute
            label={'Reference sensor battery level'}
            editable={false}
            isModified={false}
            inputId="ref-sensor-battery"
            hideDesired={true}
            reportedValue={batteryLevelString(
              state.reported.referenceSensorBatteryLevel?.value,
            )}
          />

          <StateAttribute
            label={'Network mode'}
            editable={false}
            isModified={false}
            inputId="network-mode"
            hideDesired={true}
            reportedValue={state.reported.networkMode?.value?.toString()}
          />

          <StateAttribute
            label={'Network signal strength'}
            editable={false}
            isModified={false}
            inputId="network-signal-strenght"
            hideDesired={true}
            reportedValue={nssString(
              state.reported.networkSignalStrength?.value,
            )}
          />
        </div>

        <TouwiButton
          text="Save"
          onClick={onSaveClick}
          disabled={
            !stateHasChanges || stateStore.isLoading || stateStore.isSaving
          }
        />
      </div>
    </>
  );
}

function batteryLevelString(value?: number | null) {
  if (value === undefined) {
    return undefined;
  }

  if (value === null) {
    return null;
  }

  return `${value}% (${batterLevelToMilliVolts(value)} mV)`;
}

function nssString(value?: number | null) {
  if (value === undefined) {
    return undefined;
  }

  if (value === null) {
    return null;
  }

  return `${value}/10`;
}

function batterLevelToMilliVolts(percents: number) {
  if (percents < 0) {
    percents = 0;
  }

  if (percents > 100) {
    percents = 100;
  }

  const vMin = 2000;
  const vMax = 3000;
  const vRange = vMax - vMin;

  return vMin + (percents / 100) * vRange;
}

import {
  GatewayDesiredStateUpdateInputDto,
  GatewayStateDto,
} from '../hc-api/api';
import { create } from 'zustand';
import { useErrorsStore } from '../errors/ErrorsStore';
import { gatewaysStateApi } from '../hc-api';

export interface GatewayStateStore {
  gatewayId: string | null;

  state: GatewayStateDto | null;

  isLoading: boolean;

  setGatewayId: (gatewayId: string) => void;

  update: (state: GatewayStateDto) => void;

  refresh: () => void;

  reset: () => void;

  isSaving: boolean;

  save: (input: GatewayDesiredStateUpdateInputDto) => void;
}

export const useGatewayStateStore = create<GatewayStateStore>()((set, get) => ({
  gatewayId: null,
  state: null,
  isLoading: false,
  isSaving: false,

  setGatewayId: (gatewaysId: string) => {
    if (get().gatewayId === gatewaysId) {
      return;
    }

    set({ gatewayId: gatewaysId, state: null });
    get().refresh();
  },

  update: (state: GatewayStateDto) => {
    if (get().gatewayId !== state.gatewayId) {
      return;
    }

    set({ state: state, isLoading: false });
  },

  refresh: async () => {
    if (get().isLoading) {
      return;
    }

    try {
      let done = false;
      while (!done) {
        const gatewaysId = get().gatewayId;
        if (!gatewaysId) {
          return;
        }

        set({ isLoading: true });

        const response = await gatewaysStateApi.getState(gatewaysId);
        if (!get().isLoading) {
          return;
        }

        if (get().gatewayId === gatewaysId) {
          const state = response.data;
          set({ state: state });
          done = true;
        }
      }
    } catch (e: unknown) {
      console.error('failed to load gateway state', e);
      useErrorsStore.getState().add(`Failed to load gateway state: ${e}`);
    } finally {
      set({ isLoading: false });
    }
  },

  reset: () => {
    set({
      gatewayId: null,
      state: null,
      isLoading: false,
    });
  },

  save: async (input: GatewayDesiredStateUpdateInputDto) => {
    if (get().isSaving) {
      return;
    }

    const gatewayId = get().gatewayId;
    if (!gatewayId) {
      return;
    }

    set({ isSaving: true });
    try {
      const response = await gatewaysStateApi.updateDesiredState(
        gatewayId,
        input,
      );
      const newState = response.data;
      if (gatewayId !== get().gatewayId) {
        return;
      }
      set({ state: newState });
    } catch (e: unknown) {
      console.error('failed to save desired state', e);
      useErrorsStore.getState().add(`Failed to save desired state: ${e}`);
    } finally {
      set({ isSaving: false });
    }
  },
}));

import { useEffect, useState } from 'react';
import { useErrorsStore } from '../errors/ErrorsStore';
import { gwCommandsApi } from '../hc-api';
import { GatewayStateResponseDto } from '../hc-api/api';
import axios from 'axios';

export interface GetGatewayStateCommandState {
  isRunning: boolean;
  result?: GatewayStateResponseDto;
  error?: Error;
}

export function useGetGatewayState(
  gatewayId: string,
): GetGatewayStateCommandState {
  const addError = useErrorsStore((store) => store.add);

  const [state, setState] = useState<GetGatewayStateCommandState>({
    isRunning: false,
  });

  useEffect(() => {
    const abortController = new AbortController();

    setState({
      isRunning: true,
    });

    gwCommandsApi
      .gatewayStateCommand(gatewayId, { signal: abortController.signal })
      .then((response) => {
        setState({
          isRunning: false,
          result: response.data,
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.error('Failed to get gateway state', e);
          addError(
            `Failed to get gateway state: ${e?.response?.data?.message ?? e}`,
          );

          setState({
            isRunning: false,
            result: undefined,
            error: e,
          });
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  return state;
}

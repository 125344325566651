import React from 'react';
import { TouwiButtonStyle } from './TouwiButtonStyle';

export interface ButtonProps {
  readonly style?: TouwiButtonStyle | undefined;
  readonly disabled?: boolean | undefined;
  readonly text: string;
  readonly type?: 'submit' | 'reset' | 'button' | undefined;
  readonly onClick?: () => void | undefined;
}

export function TouwiButton(props: ButtonProps) {
  const onCLick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const styleClass = {
    normal: 'bg-blue-800 hover:bg-blue-700 disabled:bg-gray-800/50',
    dangerous: 'bg-red-800 hover:bg-red-700 disabled:bg-red-800/50',
  };

  return (
    <button
      disabled={props.disabled}
      className={`rounded p-2 text-sm text-white  ${
        styleClass[props.style ?? 'normal']
      }`}
      type={props.type}
      onClick={onCLick}
    >
      {props.text}
    </button>
  );
}

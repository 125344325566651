import { ReactNode } from 'react';

export interface StateAttributeProps {
  inputId: string;
  label: string;
  desiredValue?: string | null | undefined;
  onUpdateDesiredValue?: (newValue: string) => void;
  isModified: boolean;
  hideDesired?: boolean;

  reportedValue: string | null | undefined;
  editable: boolean;
  options?: string[];
  disabled?: boolean;

  extraInput?: ReactNode;
}

const EMPTY_VALUE_PLACEHOLDER = '--';

export function StateAttribute(props: StateAttributeProps) {
  return (
    <div className="space flex flex-col rounded border-[1px] hover:bg-blue-800/10">
      <label
        className="p-2 text-start font-bold text-blue-800"
        htmlFor={props.inputId}
      >
        {props.label}
        {props.isModified ? ' *' : ''}
      </label>
      {!props.hideDesired && (
        <div className="flex flex-wrap items-center gap-2 p-2 text-start">
          <div className="font-bold">Desired:</div>
          {props.editable && !props.options && (
            <div className="flex flex-wrap gap-2">
              <input
                id={props.inputId}
                className="rounded border border-blue-800 p-1 text-black"
                value={props.desiredValue ?? ''}
                onChange={(e) => props.onUpdateDesiredValue?.(e.target.value)}
                disabled={props.disabled}
              />
              {props.extraInput && props.extraInput}
            </div>
          )}
          {props.editable && props.options && (
            <div className="flex flex-wrap gap-2">
              <select
                name={props.inputId}
                id={props.inputId}
                className="rounded border border-blue-800 p-1 text-black"
                value={props.desiredValue ?? props.options[0] ?? ''}
                onChange={(e) => props.onUpdateDesiredValue?.(e.target.value)}
                disabled={props.disabled}
              >
                {props.options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {props.extraInput && props.extraInput}
            </div>
          )}
          {!props.editable && (
            <div id={props.inputId}>
              {props.desiredValue ?? EMPTY_VALUE_PLACEHOLDER}
            </div>
          )}
        </div>
      )}
      <div className="flex gap-2 p-2 text-start">
        <div className="font-bold">Reported:</div>
        <div>{props.reportedValue ?? EMPTY_VALUE_PLACEHOLDER}</div>
      </div>
    </div>
  );
}

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { tryToken } from '../hc-api';
import { useErrorsStore } from '../errors/ErrorsStore';

export interface AuthStore {
  readonly token: string | null;

  readonly isAuthenticated: boolean;

  readonly isAuthenticating: boolean;

  readonly setToken: (token: string | null) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      token: null,
      isAuthenticated: false,
      isAuthenticating: false,
      setToken: async (token) => {
        if (!token) {
          set({
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
          });
          return;
        } else {
          set({ isAuthenticating: true });
          try {
            await tryToken(token);
            set({
              token: token,
              isAuthenticated: true,
            });
          } catch (e: unknown) {
            console.error('auth error', e);
            useErrorsStore.getState().add(`Login failed: ${e}`);
          } finally {
            set({ isAuthenticating: false });
          }
        }
      },
    }),
    {
      name: 'auth-store',
    },
  ),
);

import { EventsPageDtoItemsInner } from '../hc-api/api';
import { create } from 'zustand';
import { useErrorsStore } from '../errors/ErrorsStore';
import { eventsApi } from '../hc-api';

const PAGE_SIZE = 50;

export interface EventsStore {
  events: EventsPageDtoItemsInner[];

  gatewayId: string | null;

  isLoading: boolean;

  hasMore: boolean;

  setGatewayId: (gatewayId: string) => void;

  loadMore: () => void;

  putEvents: (events: EventsPageDtoItemsInner[]) => void;

  reset: () => void;
}

export const useEventsStore = create<EventsStore>()((set, get) => ({
  events: [],
  gatewayId: null,
  isLoading: false,
  hasMore: false,

  setGatewayId: async (gatewayId: string) => {
    if (get().gatewayId === gatewayId) {
      return;
    }

    set({ gatewayId: gatewayId, events: [], hasMore: false });
    get().loadMore();
  },

  loadMore: async () => {
    if (get().isLoading) {
      return;
    }

    const gatewayId = get().gatewayId;
    if (!gatewayId) {
      return;
    }

    set({ isLoading: true });

    try {
      const response = await eventsApi.getEvents(
        get().events.length,
        PAGE_SIZE,
        false,
        undefined,
        ['triggeredAt[desc]'],
        undefined,
        [gatewayId],
      );
      const loadedEvents = response.data.items;
      if (!get().isLoading) {
        return;
      }

      if (get().gatewayId !== gatewayId) {
        return;
      }
      set({
        events: mergeEvents(get().events, loadedEvents),
        hasMore: loadedEvents.length == PAGE_SIZE,
      });
    } catch (e: unknown) {
      console.log('failed to load events', e);
      useErrorsStore().add(`Failed to load events: ${e}`);
    } finally {
      if (get().gatewayId === gatewayId) {
        set({ isLoading: false });
      }
    }
  },

  putEvents: async (newEvents: EventsPageDtoItemsInner[]) => {
    const gatewayId = get().gatewayId;
    if (!gatewayId) {
      return;
    }

    newEvents = newEvents.filter((e) => e.gatewayId === gatewayId);
    if (newEvents.length === 0) {
      return;
    }

    const existingEvents = get().events;

    set({ events: mergeEvents(existingEvents, newEvents) });
  },

  reset: () => {
    set({
      events: [],
      gatewayId: null,
      isLoading: false,
      hasMore: false,
    });
  },
}));

function mergeEvents(
  a: EventsPageDtoItemsInner[],
  b: EventsPageDtoItemsInner[],
) {
  const eventsMap = new Map<number, EventsPageDtoItemsInner>();
  a.forEach((e) => eventsMap.set(e.id, e));
  b.forEach((e) => eventsMap.set(e.id, e));

  const events = Array.from(eventsMap.values());
  events.sort((a, b) => b.triggeredAt.localeCompare(a.triggeredAt));
  return events;
}

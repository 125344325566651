import { Header } from './Header';
import { Gateways } from '../gateways/Gateways';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../not-found/NotFound';
import { GatewayScreen } from '../gateways/GatewayScreen';
import { useMqtt } from '../../model/mqtt/UseMqtt';

export function Home() {
  const mqtt = useMqtt();
  console.log('[home] mqtt:', mqtt ? 'ok' : 'null');

  return (
    <div className="flex flex-col">
      <Header />
      <Routes>
        <Route path="" element={<Gateways />} />
        <Route path="/gateways" element={<Gateways />} />
        <Route path="/gateways/:gatewayId" element={<GatewayScreen />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

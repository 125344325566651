import { useMqttSubscription } from '../mqtt/UseMqttSubscription';
import { EventsPageDtoItemsInner } from '../hc-api/api';
import { useEventsStore } from './UseEventsStore';

export function useEventsMqttSubscription(gatewayId: string) {
  const putEvents = useEventsStore((store) => store.putEvents);

  useMqttSubscription(`hc/c2c/gateways/${gatewayId}/events`, (_, payload) => {
    try {
      const events = JSON.parse(payload.toString()) as {
        items: EventsPageDtoItemsInner[];
      };
      console.log('new events received', events);
      if (!events.items || events.items.length === 0) {
        return;
      }

      putEvents(events.items);
    } catch (e: unknown) {
      console.error('failed to parse event', e);
    }
  });
}
